import AbstractVanModule from "@/van/AbstractVanModule";
import {checkDaemonApi, approveApi} from "@/van/ksnet/RequestKsnet";
import VanProcessError from "@/error/van/VanProcessError";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

// 정상 코드들
// 0000 - 정상
// 7001, 5008 - 기승인 취소 코드
const SUCCESS_CODE = "0000";
const ALREADY_CANCELED_CODES = ["7001", "5008"];

// 거래자구분
const CASH_GBN_CONSUMER = "0"; // 소비자 소득공제
const CASH_GBN_BUSINESS = "1"; // 사업자 지출공제

export default class KsnetVanModule extends AbstractVanModule {
  responseReady
  failLogPrefix

  constructor(port) {
    super(port);
    this.responseReady = true;
  }

  async cardApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cardApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cardCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;
    try {
      return await this._cardCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async rollback(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    let requestBody = this._createRollbackRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);
        if (response.RESPCODE === SUCCESS_CODE) {
          return {
            tid: payInfo.id,
            status: this.STATUS.ROLLBACK
          };
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`
          );
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async viewCheck(payInfo) {
    this.failLogPrefix = "[수표조회 실패]";

    let requestBody = this._createViewCheckRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);
        // eslint-disable-next-line no-case-declarations
        const responseToString = JSON.stringify(response, null, 2);

        if (response.RESPCODE === SUCCESS_CODE) {
          return {
            tid: payInfo.id,
            status: this.STATUS.OK,
            approvalDateTime: moment(response.TRADETIME, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
            message: `${response.MESSAGE1.trim()} ${response.MESSAGE2.trim()} ${response.NOTICE1.trim()} ${response.NOTICE2.trim()}`,
            printMessage: `${response.MESSAGE1.trim()} ${response.MESSAGE2.trim()} ${response.NOTICE1.trim()} ${response.NOTICE2.trim()}`,
            saleNo: response.TELEGRAMNO ? response.TELEGRAMNO.trim() : null,
            vanResponseString: responseToString,
            vanResponseData: responseToString,
            termId: response.TERMID
          };
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`,
            response.RES === "1000" ? "SELFCANCEL" : "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardApprovalRequest(payInfo) {
    let requestBody = this._createCardApprovalRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);

        if (response.RESPCODE === SUCCESS_CODE) {
          return await this._createCardApprovalResult(response, payInfo);
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`,
            response.RES === "1000" ? "SELFCANCEL" : "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardCancelRequest(payInfo) {
    let requestBody = this._createCardCancelRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);

        if (response.RESPCODE === SUCCESS_CODE) {
          let result = await this._createCardApprovalResult(response, payInfo);
          result.status = this.STATUS.CANCELED;
          return result;
        } else if (ALREADY_CANCELED_CODES.includes(response.RESPCODE)) {
          let result = await this._createCardApprovalResult(response, payInfo);
          result.status = this.STATUS.ALREADY_CANCELED;
          return result;
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`,
            response.RES === "1000" ? "SELFCANCEL" : "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptApprovalRequest(payInfo) {
    let requestBody = this._createCashReceiptApprovalRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);
        // eslint-disable-next-line no-case-declarations
        const responseToString = JSON.stringify(response, null, 2);

        if (response.RESPCODE === SUCCESS_CODE) {
          return await this._createCashReceiptApprovalResult(response, payInfo);
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`,
            response.RES === "1000" ? "SELFCANCEL" : "FAIL",
            responseToString,
            responseToString);
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptCancelRequest(payInfo) {
    let requestBody = this._createCashReceiptCancelRequestBody(payInfo);

    let status = await this._checkDemonStatus();

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const response = await approveApi(this._getRequestUrl(), requestBody);
        // eslint-disable-next-line no-case-declarations
        const responseToString = JSON.stringify(response, null, 2);

        if (response.RESPCODE === SUCCESS_CODE) {
          let result = await this._createCashReceiptApprovalResult(response, payInfo);
          result.status = this.STATUS.CANCELED;
          return result;
        } else if (ALREADY_CANCELED_CODES.includes(response.RESPCODE)) {
          let result = await this._createCashReceiptApprovalResult(response, payInfo);
          result.status = this.STATUS.ALREADY_CANCELED;
          return result;
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response.MSG ? response.MSG.trim() : ""} ${response.MESSAGE1 ? response.MESSAGE1 : ""} ${response.MESSAGE2 ? response.MESSAGE2 : ""}`,
            response.RES === "1000" ? "SELFCANCEL" : "FAIL",
            responseToString,
            responseToString);
        }
      case "1003":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _checkDemonStatus() {
    const rtn = await checkDaemonApi(this._getRequestUrl());

    return rtn.RES;
  }

  _getRequestUrl() {
    return `https://${this.host}:${this.port}/`;
  }

  async _createCardApprovalResult(response, payInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const purchaseInfo = await this._convertPurchaseCodeToPurchaseInfo(response.PURCHASECODE);

    if (!purchaseInfo) {
      return {
        tid: payInfo.id,
        status: this.STATUS.APPROVED,
        approvalDateTime: moment(response.TRADETIME, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
        approvalNo: response.APPROVALNO.trim(),
        totalAmount: Number(payInfo.totalAmount),
        taxAmount: Number(payInfo.taxAmount),
        vatAmount: Number(payInfo.vatAmount),
        notaxAmount: Number(payInfo.notaxAmount),
        serviceAmount: Number(payInfo.serviceAmount),
        cardNo: response.FILLER.trim(),
        divideTerm: Number(payInfo.divideTerm),
        purchaseName: response.PURCHASENAME.trim(),
        issueCompanyName: response.MESSAGE1.trim(),
        merchantNo: response.MERCHANTNUMBER.trim(),
        saleNo: response.TELEGRAMNO ? response.TELEGRAMNO.trim() : null,
        message: `${response.PURCHASENAME.trim()}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: responseToString,
        vanResponseData: responseToString,
        termId: response.TERMID
      };
    }

    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(response.TRADETIME, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response.APPROVALNO.trim(),
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      cardNo: response.FILLER.trim(),
      divideTerm: Number(payInfo.divideTerm),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: response.PURCHASENAME.trim(),
      issueCompanyName: response.MESSAGE1.trim(),
      merchantNo: response.MERCHANTNUMBER.trim(),
      saleNo: response.TELEGRAMNO ? response.TELEGRAMNO.trim() : null,
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.TERMID
    };
  }

  async _createCashReceiptApprovalResult(response, payInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(response.TRADETIME, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response.APPROVALNO.trim(),
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      proofKind: payInfo.proofKind,
      proofNo: response.FILLER.trim(),
      printMessage: `${response.MESSAGE1.trim()} ${response.MESSAGE2.trim()} ${response.NOTICE1.trim()} ${response.NOTICE2.trim()}`,
      saleNo: response.TELEGRAMNO ? response.TELEGRAMNO.trim() : null,
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.TERMID
    };
  }

  /**
   * VAN 사 매입처 코드를 ERP 내 매입처 정보로 변환
   * @param purchaseCode
   * @return {Promise<*>}
   * @private
   */
  async _convertPurchaseCodeToPurchaseInfo(purchaseCode) {
    const placesOfCardPurchase = await GolfErpAPI.getPlacesOfCardPurchase("KSNET");

    const cardList = placesOfCardPurchase.map(
      ({ purchaseId, purchaseName, vanMappingList }) => {
        return {
          purchaseId: purchaseId,
          purchaseName: purchaseName,
          vanMapCode: vanMappingList[0].vanMapCode
        };
      });

    return cardList.find(confCard => confCard.vanMapCode === purchaseCode);
  }

  _createCardApprovalRequestBody(payInfo) {
    let requestMsg = "";

    // 전문길이 마지막에 입력
    requestMsg += String.fromCharCode(2);               // STX
    requestMsg += "IC";                                       // 거래구분
    requestMsg += "01";                                       // 업무구분
    requestMsg += "0200";                                     // 전문구분
    requestMsg += "N";                                        // 거래형태
    requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
    requestMsg += "    ";                                     // 업체정보
    requestMsg += payInfo.id.toString().padStart(12, "0");    // 전문일련번호
    requestMsg += " ";                                        // Pos Entry Mode
    requestMsg += "                    ";                		  // 거래고유번호
    requestMsg += "                    ";                     // 암호화하지않은 카드번호
    requestMsg += " ";                                        // 암호화여부
    requestMsg += "                ";                         // SW모델번호
    requestMsg += "                ";                         // CAT or Reader 모델번호
    requestMsg += "                                        "; // 암호화정보
    requestMsg += "                                     ";    // 카드번호
    requestMsg += String.fromCharCode(28);              // FS
    requestMsg += payInfo.divideTerm.toString().padStart(2, "0");        // 할부개월수
    requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
    requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
    requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
    requestMsg += payInfo.taxAmount.toString().padStart(12, "0");        // 공급금액
    requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
    requestMsg += "  ";                                       // WorkingKey Index
    requestMsg += "                ";                         // 비밀번호
    requestMsg += "            ";                   				  // 원거래승인번호
    requestMsg += "      ";                          				  // 원거래승인일자

    for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
    requestMsg += payInfo.totalAmount > 50000 ? "F" : "X";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
    requestMsg += String.fromCharCode( 3);              // ETX
    requestMsg += String.fromCharCode(13);              // CR

    const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

    requestMsg = "AP" + telegramLen + requestMsg;

    return requestMsg;
  }

  _createCardCancelRequestBody(payInfo) {
    let requestMsg = "";

    // 전문길이 마지막에 입력
    requestMsg += String.fromCharCode(2);               // STX
    requestMsg += "IC";                                       // 거래구분
    requestMsg += "01";                                       // 업무구분
    requestMsg += "0420";                                     // 전문구분
    requestMsg += "N";                                        // 거래형태
    requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
    requestMsg += "    ";                                     // 업체정보
    requestMsg += "            ";                             // 전문일련번호
    requestMsg += " ";                                        // Pos Entry Mode
    requestMsg += "                    ";                		  // 거래고유번호
    requestMsg += "                    ";                     // 암호화하지않은 카드번호
    requestMsg += " ";                                        // 암호화여부
    requestMsg += "                ";                         // SW모델번호
    requestMsg += "                ";                         // CAT or Reader 모델번호
    requestMsg += "                                        "; // 암호화정보
    requestMsg += "                                     ";    // 카드번호
    requestMsg += String.fromCharCode(28);              // FS
    requestMsg += payInfo.divideTerm.toString().padStart(2, "0");        // 할부개월수
    requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
    requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
    requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
    requestMsg += payInfo.taxAmount.toString().padStart(12, "0");        // 공급금액
    requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
    requestMsg += "  ";                                       // WorkingKey Index
    requestMsg += "                ";                         // 비밀번호
    requestMsg += payInfo.approvalNo.padEnd(12, " ");         // 원거래승인번호
    requestMsg += moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format("YYMMDD"); // 원거래승인일자

    for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
    requestMsg += payInfo.totalAmount > 50000 ? "F" : "X";    // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
    requestMsg += String.fromCharCode( 3);              // ETX
    requestMsg += String.fromCharCode(13);              // CR

    const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

    requestMsg = "AP" + telegramLen + requestMsg;

    return requestMsg;
  }

  _createCashReceiptApprovalRequestBody(payInfo) {
    let proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);

    let requestMsg = "";

    // 전문길이 마지막에 입력
    requestMsg += String.fromCharCode(2);               // STX
    requestMsg += "HK";                                       // 거래구분
    requestMsg += "01";                                       // 업무구분
    requestMsg += "0200";                                     // 전문구분
    requestMsg += "N";                                        // 거래형태
    requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
    requestMsg += "    ";                                     // 업체정보
    requestMsg += payInfo.id.toString().padStart(12, "0");    // 전문일련번호
    requestMsg += " ";                                        // Pos Entry Mode
    requestMsg += "                    ";                		  // 거래고유번호
    requestMsg += "                    ";                     // 암호화하지않은 카드번호
    requestMsg += " ";                                        // 암호화여부
    requestMsg += "".padEnd(16, " ");                         // SW모델번호
    requestMsg += "".padEnd(16, " ");                         // CAT or Reader 모델번호
    requestMsg += "                                        "; // 암호화정보
    requestMsg += proof.proofNo ? proof.proofNo.padEnd(37, " ") : "".padEnd(37, " ");    // 현금영수증 자진발급인 경우 기본 증빙번호 Set.(DEFAULT_PROOF_NO)
    requestMsg += String.fromCharCode(28);              // FS
    requestMsg += "0";                                        // 거래자 구분 (앞자리 1Byte : 취소 사유(취소시만 사용 : 1.거래취소, 2.오류발급취소, 3.기타) - 승인시 '0' Set)
    requestMsg += proof.proofKind;                            // 거래자 구분 (뒷자리 1Byte : "0" - 개인 소득공제, "1" - 사업자 지출증빙)
    requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
    requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
    requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
    requestMsg += (payInfo.taxAmount + payInfo.notaxAmount).toString().padStart(12, "0");        // 공급금액
    requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
    requestMsg += "  ";                                       // WorkingKey Index
    requestMsg += "                ";                         // 비밀번호
    requestMsg += "            ";                   				  // 원거래승인번호
    requestMsg += "      ";                          				  // 원거래승인일자

    for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
    requestMsg += " ";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
    requestMsg += String.fromCharCode( 3);              // ETX
    requestMsg += String.fromCharCode(13);              // CR

    const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

    requestMsg = "AP" + telegramLen + requestMsg;

    return requestMsg;
  }

  _createCashReceiptCancelRequestBody(payInfo) {
    let proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);

    let requestMsg = "";

    // 전문길이 마지막에 입력
    requestMsg += String.fromCharCode(2);               // STX
    requestMsg += "HK";                                       // 거래구분
    requestMsg += "01";                                       // 업무구분
    requestMsg += "0420";                                     // 전문구분
    requestMsg += "N";                                        // 거래형태
    requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
    requestMsg += "    ";                                     // 업체정보
    requestMsg += payInfo.id.toString().padStart(12, "0");    // 전문일련번호
    requestMsg += " ";                                        // Pos Entry Mode
    requestMsg += "                    ";                		  // 거래고유번호
    requestMsg += "                    ";                     // 암호화하지않은 카드번호
    requestMsg += " ";                                        // 암호화여부
    requestMsg += "".padEnd(16, " ");                         // SW모델번호
    requestMsg += "".padEnd(16, " ");                         // CAT or Reader 모델번호
    requestMsg += "                                        "; // 암호화정보
    requestMsg += proof.proofNo ? proof.proofNo.padEnd(37, " ") : "".padEnd(37, " ");    // 현금영수증 자진발급인 경우 기본 증빙번호 Set.(DEFAULT_PROOF_NO)
    requestMsg += String.fromCharCode(28);              // FS
    requestMsg += "1";                                        // 거래자 구분 (앞자리 1Byte : 취소 사유(취소시만 사용 : 1.거래취소, 2.오류발급취소, 3.기타) - 승인시 '0' Set)
    requestMsg += proof.proofKind;                            // 거래자 구분 (뒷자리 1Byte : "0" - 개인 소득공제, "1" - 사업자 지출증빙)
    requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
    requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
    requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
    requestMsg += (payInfo.taxAmount + payInfo.notaxAmount).toString().padStart(12, "0");        // 공급금액
    requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
    requestMsg += "  ";                                       // WorkingKey Index
    requestMsg += "                ";                         // 비밀번호
    requestMsg += payInfo.approvalNo.padEnd(12, " ");         // 원거래승인번호
    requestMsg += moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format("YYMMDD"); // 원거래승인일자

    for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
    requestMsg += " ";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
    requestMsg += String.fromCharCode( 3);              // ETX
    requestMsg += String.fromCharCode(13);              // CR

    const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

    requestMsg = "AP" + telegramLen + requestMsg;

    return requestMsg;
  }

  _createViewCheckRequestBody(payInfo) {
    let requestMsg = "";

    // 전문길이 마지막에 입력
    requestMsg += String.fromCharCode(2);               // STX
    requestMsg += "CH";                                       // 거래구분
    requestMsg += "01";                                       // 업무구분
    requestMsg += "0200";                                     // 전문구분
    requestMsg += "N";                                        // 거래형태
    requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
    requestMsg += "".padEnd(4, " ");                          // 업체정보
    requestMsg += "".padEnd(12, " ");                         // 전문일련번호
    requestMsg += " ";                                        // Pos Entry Mode
    requestMsg += "".padEnd(20, " ");                    		  // 거래고유번호
    requestMsg += "".padEnd(20, " ");                         // 암호화하지않은 카드번호
    requestMsg += " ";                                        // 암호화여부
    requestMsg += "".padEnd(16, " ");                         // SW모델번호
    requestMsg += "".padEnd(16, " ");                         // CAT or Reader 모델번호
    requestMsg += "".padEnd(40, " ");                         // 암호화정보
    requestMsg += (payInfo.checkPubDate.padEnd(6, " ") +            // Track II(수표조회 : 발행일자(6) +
      payInfo.checkNo.padEnd(14, " ") +          // 수표번호(8) + 판매은행(2) + 판매지점(4) +
      (payInfo.checkAccount ? payInfo.checkAccount.padEnd(6, " ") : "".padEnd(6, " ")) +          // 계좌일련번호(6) +
      payInfo.checkType.padEnd(2, " ")).padEnd(37, " ");            // 권종코드(2))
    // (권종코드 :   "13": 10 만원권,  "14": 30 만원권,  "15": 50 만원권, "16":100 만원권, "19":기타)
    requestMsg += String.fromCharCode(28);              // FS
    requestMsg += "".padStart(2, "0");                        // 할부개월수
    requestMsg += payInfo.totalAmount.toString().padStart(12, "0");                       // 총금액
    requestMsg += "".padStart(12, "0");                       // 봉사료
    requestMsg += "".padStart(12, "0");                       // 세금
    requestMsg += "".padStart(12, "0");                       // 공급금액
    requestMsg += "".padStart(12, "0");                       // 면세금액
    requestMsg += "".padEnd(2, " ");                          // WorkingKey Index
    requestMsg += "".padEnd(16, " ");                         // 비밀번호
    requestMsg += "".padEnd(12, " ");                         // 원거래승인번호
    requestMsg += "".padEnd(6, " ");                          // 원거래승인일자

    requestMsg += "".padEnd(163, " ");                        // 사용자정보~DCC
    requestMsg += "X";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
    requestMsg += String.fromCharCode( 3);              // ETX
    requestMsg += String.fromCharCode(13);              // CR

    const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

    requestMsg = "AP" + telegramLen + requestMsg;

    return requestMsg;
  }

  _createRollbackRequestBody(payInfo) {
    let requestMsg = "";

    if (payInfo.payDivision === "CARD") {
      // 전문길이 마지막에 입력
      requestMsg += String.fromCharCode(2);               // STX
      requestMsg += "IC";                                       // 거래구분
      requestMsg += "01";                                       // 업무구분
      requestMsg += "0440";                                     // 전문구분
      requestMsg += "N";                                        // 거래형태
      requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
      requestMsg += "    ";                                     // 업체정보
      requestMsg += payInfo.saleNo;                             // 전문일련번호
      requestMsg += " ";                                        // Pos Entry Mode
      requestMsg += "                    ";                		  // 거래고유번호
      requestMsg += "                    ";                     // 암호화하지않은 카드번호
      requestMsg += " ";                                        // 암호화여부
      requestMsg += "                ";                         // SW모델번호
      requestMsg += "                ";                         // CAT or Reader 모델번호
      requestMsg += "                                        "; // 암호화정보
      requestMsg += "                                     ";    // 카드번호
      requestMsg += String.fromCharCode(28);              // FS
      requestMsg += payInfo.divideTerm.toString().padStart(2, "0");        // 할부개월수
      requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
      requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
      requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
      requestMsg += payInfo.taxAmount.toString().padStart(12, "0");        // 공급금액
      requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
      requestMsg += "  ";                                       // WorkingKey Index
      requestMsg += "                ";                         // 비밀번호
      requestMsg += payInfo.approvalNo.padEnd(12, " ");         // 원거래승인번호
      requestMsg += moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format("YYMMDD"); // 원거래승인일자

      for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
      requestMsg += "X";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
      requestMsg += String.fromCharCode( 3);              // ETX
      requestMsg += String.fromCharCode(13);              // CR

      const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

      requestMsg = "AP" + telegramLen + requestMsg;

      return requestMsg;
    } else {
      let proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);

      // 전문길이 마지막에 입력
      requestMsg += String.fromCharCode(2);               // STX
      requestMsg += "HK";                                       // 거래구분
      requestMsg += "01";                                       // 업무구분
      requestMsg += "0440";                                     // 전문구분
      requestMsg += "N";                                        // 거래형태
      requestMsg += payInfo.termId ? payInfo.termId.padEnd(10, " ") : "".padEnd(10, "");             // 단말기번호 (TEST용 : DPT0TEST03)
      requestMsg += "    ";                                     // 업체정보
      requestMsg += payInfo.saleNo;                             // 전문일련번호
      requestMsg += " ";                                        // Pos Entry Mode
      requestMsg += "                    ";                		  // 거래고유번호
      requestMsg += "                    ";                     // 암호화하지않은 카드번호
      requestMsg += " ";                                        // 암호화여부
      requestMsg += "                ";                         // SW모델번호
      requestMsg += "                ";                         // CAT or Reader 모델번호
      requestMsg += "                                        "; // 암호화정보
      requestMsg += proof.proofNo ? proof.proofNo.padEnd(37, " ") : "".padEnd(37, " ");    // 현금영수증 자진발급인 경우 기본 증빙번호 Set.(DEFAULT_PROOF_NO)
      requestMsg += String.fromCharCode(28);              // FS
      requestMsg += "2";                                        // 거래자 구분 (앞자리 1Byte : 취소 사유(취소시만 사용 : 1.거래취소, 2.오류발급취소, 3.기타) - 승인시 '0' Set)
      requestMsg += proof.proofKind;                            // 거래자 구분 (뒷자리 1Byte : "0" - 개인 소득공제, "1" - 사업자 지출증빙)
      requestMsg += payInfo.totalAmount.toString().padStart(12, "0");      // 총금액
      requestMsg += payInfo.serviceAmount.toString().padStart(12, "0");    // 봉사료
      requestMsg += payInfo.vatAmount.toString().padStart(12, "0");        // 세금
      requestMsg += payInfo.taxAmount.toString().padStart(12, "0");        // 공급금액
      requestMsg += payInfo.notaxAmount.toString().padStart(12, "0");      // 면세금액
      requestMsg += "  ";                                       // WorkingKey Index
      requestMsg += "                ";                         // 비밀번호
      requestMsg += payInfo.approvalNo.padEnd(12, " ");         // 원거래승인번호
      requestMsg += moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format("YYMMDD"); // 원거래승인일자

      for(let i=0; i<163; i++) { requestMsg += " "; }		   	    // 사용자정보~DCC
      requestMsg += " ";                                        // 전자서명유무 (5만원 이하는 X = 무서명, 그 외엔 KSCAT 이미지 저장을 위해 "F")
      requestMsg += String.fromCharCode( 3);              // ETX
      requestMsg += String.fromCharCode(13);              // CR

      const telegramLen = requestMsg.length.toString().padStart(4, "0"); // 길이

      requestMsg = "AP" + telegramLen + requestMsg;

      return requestMsg;
    }
  }

  _convertToProof(proofNo, proofKind) {
    switch (proofKind) {
      case "FIT" : // 소비자소득공제
        return { proofNo : proofNo, proofKind: CASH_GBN_CONSUMER };
      case "CPR" : // 사업자지출증빙
        return { proofNo : proofNo, proofKind: CASH_GBN_BUSINESS };
      case "VOL" : // 자진발급
        return { proofNo : this.DEFAULT_PROOF_NO, proofKind: CASH_GBN_CONSUMER };
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : 현금영수증 증빙구분을 확인할 수 없습니다.`);
    }
  }
}
