import GolfERPService from "@/service/GolfERPService";
// import { getCurrentBizCode } from "@/utils/pageKeyUtil";
import { getDeviceInfo } from "@/utils/device";
// import { getAccessToken } from "@/utils/session/SessionUtil";
import {getVanModule} from "@/van/VanModuleFactory";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import VanProcessError from "@/error/van/VanProcessError";

/**
 * @typedef PayTransactionRequest
 * @type Object
 * @property {String} van
 * @property {String} payDivision
 * @property {String} approvalDivision
 * @property {number} totalAmount
 * @property {number} taxAmount
 * @property {number} vatAmount
 * @property {number} notaxAmount
 * @property {number} serviceAmount
 * @property {String} divideTerm
 * @property {String} proofKind
 * @property {String} proofNo
 * @property {String} approvalNo
 * @property {String} approvalDateTime
 * @property {String} cardNo
 * @property {String} purchaseId
 * @property {String} purchaseName
 * @property {String} issueCompanyName
 * @property {String} validTerm
 * @property {String} saleNo
 */

export async function erpPay(tid, request) {
  try {
    const port = getDemonPort();
    const vanModule = getVanModule(request.van, port);

    let result = null;

    if (request.payDivision === 'CARD') {
      if (request.approvalDivision === 'OK') {
        result = await vanModule.cardApprove(request);
      } else {
        result = request.saleNo || request.approvalDivision === "LATEST_CANCEL" ? await vanModule.rollback(request) : await vanModule.cardCancel(request);
      }
    } else if (request.payDivision === 'CASH') {
      if (request.approvalDivision === 'OK') {
        result = await vanModule.cashReceiptApprove(request);
      } else {
        result = request.saleNo ? await vanModule.rollback(request) : await vanModule.cashReceiptCancel(request);
      }
    } else if (request.payDivision === 'EASY_PAY') {
      if (request.approvalDivision === 'OK') {
        result = await vanModule.easyApprove(request);
      } else {
        result = request.saleNo || request.approvalDivision === "LATEST_CANCEL" ? await vanModule.rollback(request) : await vanModule.easyCancel(request);
      }
    } else if (request.payDivision === "CHECK") {
      result = await vanModule.viewCheck(request);
    } else {
      throw new Error("불가능한 결제 구분입니다.");
    }

    await GolfErpAPI.putPayTransaction(tid, result);

  } catch (e) {
    const result = {
      status: 'FAIL',
      message: null,
      vanResponseString: null,
      vanResponseData: null
    };

    if (e instanceof VanProcessError) {
      result.status = e.status;
      result.message = e.message;
      result.vanResponseString = e.originResponse;
      result.vanResponseData = e.originResponseData;
    } else if (e instanceof Error) {
      result.message = e.message;
    } else if (e instanceof Object) {
      result.message = JSON.stringify(e);
    } else {
      result.message = e;
    }

    await GolfErpAPI.putPayTransaction(tid, result);
    console.error(e);
  } finally {
    //
  }
}

/**
 * VAN 정산 팝업 오픈 및 결과 리턴
 * @param {PayTransactionRequest} payTransactionRequest
 * @return {Promise<{tid: number, status: String, message?: String}>}
 */
export async function openERPPay(payTransactionRequest) {

  const data = await GolfERPService.postPayTransaction(
    {
      ...payTransactionRequest,
      approvalDivision: payTransactionRequest.approvalDivision !== "LATEST_CANCEL" ? payTransactionRequest.approvalDivision : "CANCEL"
    }
  );

  if (data.status !== "OK") {
    return { tid: null, status: "FAIL", message: data.data };
  }
  const tid = data.data;
  if (payTransactionRequest.approvalDivision === "T_OK") {
    return { tid: tid, status: "APPROVED" };
  }
  payTransactionRequest.id = tid;
  await erpPay(tid, payTransactionRequest);
  const { status, message } = await GolfERPService.getPayTransaction(tid);
  return { tid: tid, status: status, message: message };
}

/**
 *
 * @return {number}
 */
function getDemonPort() {
  const deviceInfo = getDeviceInfo();
  if (deviceInfo.isCardUse) {
    return deviceInfo.cardDaemonPort;
  } else {
    //return 8085;
    throw new Error("카드 단말기를 사용하지 않습니다. 디바이스 설정 화면에서 사용 여부를 수정하실 수 있습니다.");
  }
}

/**
 * 정산 팝업을 가운데 팝업 오픈 하도록 하는 함수
 * @param {string} url
 * @param {string} title 상단 타이틀
 * @param {number} w 가로 넓이(px)
 * @param {number} h 세로 넓이(px)
 * @return {Window}
 */
// function popupCenter({ url, title, w, h }) {
//   const dualScreenLeft = window.screenLeft || window.screenX;
//   const dualScreenTop = window.screenTop || window.screenY;
//
//   const width = window.innerWidth
//     ? window.innerWidth
//     : document.documentElement.clientWidth
//     ? document.documentElement.clientWidth
//     : screen.width;
//   const height = window.innerHeight
//     ? window.innerHeight
//     : document.documentElement.clientHeight
//     ? document.documentElement.clientHeight
//     : screen.height;
//
//   const systemZoomWidth = width / window.screen.availWidth;
//   const systemZoomHeight = height / window.screen.availHeight;
//
//   const left = (width - w) / 2 / systemZoomWidth + dualScreenLeft;
//   const top = (height - h) / 2 / systemZoomHeight + dualScreenTop;
//   const newWindow = window.open(
//     url,
//     title,
//     `
//       width=${w / systemZoomWidth},
//       height=${h / systemZoomHeight},
//       top=${top},
//       left=${left},
//       scrollbars = no,
//       resizable= no,
//       menubar = no,
//       titlebar = no
//       `
//   );
//   newWindow.document.title = "Fixel Pay Assistance Popup";
//
//   window.focus();
//
//   return newWindow;
// }
