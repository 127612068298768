import AbstractVanModule from "@/van/AbstractVanModule";
import VanProcessError from "@/error/van/VanProcessError";
// import {getFormattedDate, getTodayDate} from "@/utils/date";
import {checkDaemonApi, approveApi} from "@/van/nhn/RequestNhn";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import {JSEncrypt} from "jsencrypt";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_FORMAT_YYMMDD = "YYMMDD";

// 정상 코드들
// 0000 - 정상
const SUCCESS_CODES = ["0000"];
const ALREADY_CANCELED_CODES = ["A600"];
// const ALREADY_CANCELED_CODES = ["A600", "0025"];

// 요청구분
const WORK_CODE_APPROVE_NORMAL = "0100"; // 승인 일반 요청
const WORK_CODE_CANCEL_NORMAL = "0420"; // 취소 일반 요청
const WORK_CODE_VIEW_CHECK = "0410"; // 수표 조회 요청

// 업무구분
const PROC_CODE_NORMAL_CARD = "A01"; // 일반 신용카드
const PROC_CODE_CASH_RECEIPT = "C01"; // 현금영수증
const PROC_CODE_VIEW_CHECK = "C06"; // 수표 조회

// 거래유형
const TX_TYPE_IC = "30"; // IC
const TX_TYPE_KEY_IN = "10"; // 현금영수증 서명패드 시 Key-In
// const TX_TYPE_SALE_NO_CANCLE = "97"; // 거래번호 취소 처리

// 거래자구분
const CASH_GBN_CONSUMER = "0"; // 소비자 소득공제
const CASH_GBN_BUSINESS = "1"; // 사업자 지출공제

// 취소 사유코드
// const CANCEL_CD_APPROVAL = "0"; // 승인시
// const CANCEL_CD_CANCEL = "1"; // 거래취소
// const CANCEL_CD_ERROR = "2"; // 오류발급
// const CANCEL_CD_ETC = "3"; // 기타취소

// 인터페이스 버전
// const INTERFACE_VERSION = "1.0.1.0";

// const TRACE_NO = "1"; // 전문추적 번호. 내부적 식별을 위한 값. 사용하지 않기 때문에 1로 고정

// POS->VCAT 필드 암호화 공개키
const POS_RSA_PUB_KEY = "-----BEGIN PUBLIC KEY-----\n"
  + "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDTcgVwFrnYwkePEdt7e+yq2Z6W\n"
  + "6RIRKgB4rKBLuigjv/5q/dx/p7j2K36lzgEPeDh5lrRuhbUsdyZFPrwfRNO8LYLs\n"
  + "kkdz0Do7T2yZOFQb8eZMcoiXDRonNcq11AyQTQovPifa1fGKeEWFATfIKz68kwd6\n"
  + "K2mprCsVib1xSlQimQIDAQAB\n"
  + "-----END PUBLIC KEY-----";

export default class NHNVanModule extends AbstractVanModule {
  responseReady
  failLogPrefix

  constructor(port) {
    super(port);
    this.responseReady = true;
  }

  _getRequestUrl() {
    return `https://${this.host}:${this.port}/`;
  }

  async cardApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cardApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cardCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;
    try {
      return await this._cardCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async viewCheck(payInfo) {
    this.failLogPrefix = "[수표조회 실패]";

    console.log('viewCheck.payInfo.===>', payInfo);

    let requestBody = await this._createViewCheckRequestBody(payInfo);

    console.log('viewCheck.requestBody.===>', requestBody);

    let status = await this._checkDemonStatus();

    console.log('viewCheck.status.===>', status);

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl(), requestBody);

        console.log('viewCheck.response.===>', response);
        console.log('viewCheck.response.RES_CD.===>', response?.RES_CD);
        console.log('viewCheck.response.RES_MSG.===>', response?.RES_MSG);
        console.log('viewCheck.response.RES_MSG_1.===>', response?.RES_MSG_1);

        if (SUCCESS_CODES.includes(response?.RES_CD)) {
          return {
            tid: payInfo.id,
            status: this.STATUS.OK,
            approvalDateTime: moment(response.OTX_DT, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
            saleNo: response.TRANS_NO,
            vanResponseString: JSON.stringify(response, null, 2),
            vanResponseData: JSON.stringify(response, null, 2),
            termId: response.TERM_ID
          };
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response?.RES_MSG ? response?.RES_MSG.trim() : ""} ${response?.RES_MSG_1 ? (response?.RES_MSG !== response?.RES_MSG_1 ? response?.RES_MSG_1.trim() : "") : ""}`,
            "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardApprovalRequest(payInfo) {
    let requestBody = this._createCardApprovalRequestBody(payInfo);

    console.log('cardApproval.requestBody.===>', requestBody);

    let status = await this._checkDemonStatus();

    console.log('cardApproval.status.===>', status);

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl(), requestBody);

        console.log('cardApproval.response.===>', response);
        console.log('cardApproval.response.RES_CD.===>', response?.RES_CD);
        console.log('cardApproval.response.RES_MSG.===>', response?.RES_MSG);
        console.log('cardApproval.response.RES_MSG_1.===>', response?.RES_MSG_1);

        if (SUCCESS_CODES.includes(response?.RES_CD)) {
          return await this._createCardApprovalResult(response, payInfo);
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response?.RES_MSG ? response?.RES_MSG.trim() : ""} ${response?.RES_MSG_1 ? (response?.RES_MSG !== response?.RES_MSG_1 ? response?.RES_MSG_1.trim() : "") : ""}`,
            "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardCancelRequest(payInfo) {
    let requestBody = this._createCardCancelRequestBody(payInfo);

    console.log('cardCancel.requestBody.===>', requestBody);

    let status = await this._checkDemonStatus();

    console.log('cardCancel.status.===>', status);

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl(), requestBody);

        console.log('cardCancel.response.===>', response);
        console.log('cardCancel.response.RES_CD.===>', response?.RES_CD);
        console.log('cardCancel.response.RES_MSG.===>', response?.RES_MSG);
        console.log('cardCancel.response.RES_MSG_1.===>', response?.RES_MSG_1);

        if (SUCCESS_CODES.includes(response?.RES_CD)) {
          let result = await this._createCardApprovalResult(response, payInfo);
          result.status = this.STATUS.CANCELED;
          return result;
        } else if (ALREADY_CANCELED_CODES.includes(response?.RES_CD)) {
          let result = await this._createCardApprovalResult(response, payInfo);
          result.status = this.STATUS.ALREADY_CANCELED;
          return result;
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response?.RES_MSG ? response?.RES_MSG.trim() : ""} ${response?.RES_MSG_1 ? (response?.RES_MSG !== response?.RES_MSG_1 ? response?.RES_MSG_1.trim() : "") : ""}`,
            "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptApprovalRequest(payInfo) {
    console.log('cashRequest.payInfo.===>', payInfo);
    let requestBody = this._createCashReceiptApprovalRequestBody(payInfo);

    console.log('cashApproval.requestBody.===>', requestBody);

    let status = await this._checkDemonStatus();

    console.log('cashApproval.status.===>', status);

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl(), requestBody);

        console.log('cashApproval.response.===>', response);
        console.log('cashApproval.response.RES_CD.===>', response?.RES_CD);
        console.log('cashApproval.response.RES_MSG.===>', response?.RES_MSG);
        console.log('cashApproval.response.RES_MSG_1.===>', response?.RES_MSG_1);

        if (SUCCESS_CODES.includes(response?.RES_CD)) {
          return await this._createCashReceiptApprovalResult(response, payInfo);
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response?.RES_MSG ? response?.RES_MSG.trim() : ""} ${response?.RES_MSG_1 ? (response?.RES_MSG !== response?.RES_MSG_1 ? response?.RES_MSG_1.trim() : "") : ""}`,
            "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptCancelRequest(payInfo) {
    console.log('cashCancelRequest.payInfo.===>', payInfo);
    let requestBody = this._createCashReceiptCancelRequestBody(payInfo);

    console.log('cashCancel.requestBody.===>', requestBody);

    let status = await this._checkDemonStatus();

    console.log('cashCancel.status.===>', status);

    switch (status) {
      case "0000":
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl(), requestBody);

        console.log('cashCancel.response.===>', response);
        console.log('cashCancel.response.RES_CD.===>', response?.RES_CD);
        console.log('cashCancel.response.RES_MSG.===>', response?.RES_MSG);
        console.log('cashCancel.response.RES_MSG_1.===>', response?.RES_MSG_1);

        if (SUCCESS_CODES.includes(response?.RES_CD)) {
          let result = await this._createCashReceiptApprovalResult(response, payInfo);
          result.status = this.STATUS.CANCELED;
          return result;
        } else if (ALREADY_CANCELED_CODES.includes(response?.RES_CD)) {
          let result = await this._createCashReceiptApprovalResult(response, payInfo);
          result.status = this.STATUS.ALREADY_CANCELED;
          return result;
        } else {
          throw new VanProcessError(
            `[${this.failLogPrefix}] ${response?.RES_MSG ? response?.RES_MSG.trim() : ""} ${response?.RES_MSG_1 ? (response?.RES_MSG !== response?.RES_MSG_1 ? response?.RES_MSG_1.trim() : "") : ""}`,
            "FAIL",
            JSON.stringify(response, null, 2),
            JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _checkDemonStatus() {
    const rtn = await checkDaemonApi(this._getRequestUrl());

    console.log('rtn.===>', rtn);

    return rtn?.data?.RES_CD;
  }

  _createCardApprovalRequestBody(payInfo) {
    return {
      TERM_ID: payInfo.termId, // 단말기 ID
      PROC_CODE: PROC_CODE_NORMAL_CARD, // 전문코드 - 일반 신용카드
      WORK_CODE: WORK_CODE_APPROVE_NORMAL, // 요청구분 - 승인
      TX_TYPE: TX_TYPE_IC, // 거래유형 - IC
      INS_MON: payInfo.divideTerm.toString().padStart(2, "0"), // 할부개월수
      TOT_AMT: payInfo.totalAmount.toString(), // 총거래금액
      ORG_AMT: payInfo.taxAmount.toString(), // 과세금액 (과세에 대한 공급가액)
      DUTY_AMT: payInfo.notaxAmount.toString(), // 면세금액
      SVC_AMT: payInfo.serviceAmount.toString(), // 봉사료
      TAX_AMT: payInfo.vatAmount.toString(), // 세금
    };
  }

  _createCardCancelRequestBody(payInfo) {
    return {
      TERM_ID: payInfo.termId, // 단말기 ID
      PROC_CODE: PROC_CODE_NORMAL_CARD, // 전문코드 - 일반 신용카드
      WORK_CODE: WORK_CODE_CANCEL_NORMAL, // 요청구분 - 취소
      TX_TYPE: TX_TYPE_IC, // 거래유형 - IC
      TX_CANCEL_FLAG: "0", // 거래취소FLAG - 일반취소
      TOT_AMT: payInfo.totalAmount.toString(), // 총거래금액
      OTX_DT: moment(payInfo.approvalDateTime).format(DATE_FORMAT_YYMMDD),
      AUTH_NO: payInfo.approvalNo, // 승인번호
    };
  }

  _createCashReceiptApprovalRequestBody(payInfo) {
    const encData = payInfo.proofKind === "VOL" ? this.DEFAULT_PROOF_NO : payInfo.proofNo;
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(POS_RSA_PUB_KEY);
    const jsEncryptEncData = encData ? jsEncrypt.encrypt(encData).toString() : null;

    return {
      TERM_ID: payInfo.termId, // 단말기 ID
      PROC_CODE: PROC_CODE_CASH_RECEIPT, // 전문코드 - 현금 영수증
      WORK_CODE: WORK_CODE_APPROVE_NORMAL, // 요청구분 - 승인
      TX_TYPE: TX_TYPE_KEY_IN, // 거래유형 - KEY-IN
      TOT_AMT: payInfo.totalAmount.toString(), // 총거래금액
      ORG_AMT: payInfo.taxAmount.toString(), // 과세금액 (과세에 대한 공급가액)
      DUTY_AMT: payInfo.notaxAmount.toString(), // 면세금액
      SVC_AMT: payInfo.serviceAmount.toString(), // 봉사료
      TAX_AMT: payInfo.vatAmount.toString(), // 세금
      CASH_GBN: payInfo.proofKind === "CPR" ? CASH_GBN_BUSINESS : CASH_GBN_CONSUMER,
      ENC_DATA: jsEncryptEncData,
    };
  }

  _createCashReceiptCancelRequestBody(payInfo) {
    const encData = payInfo.proofKind === "VOL" ? this.DEFAULT_PROOF_NO : payInfo.proofNo;
    const jsEncrypt = new JSEncrypt();
    jsEncrypt.setPublicKey(POS_RSA_PUB_KEY);
    const jsEncryptEncData = encData ? jsEncrypt.encrypt(encData).toString() : null;

    return {
      TERM_ID: payInfo.termId, // 단말기 ID
      PROC_CODE: PROC_CODE_CASH_RECEIPT, // 전문코드 - 현금 영수증
      WORK_CODE: WORK_CODE_CANCEL_NORMAL, // 요청구분 - 취소
      TX_TYPE: TX_TYPE_KEY_IN, // 거래유형 - KEY-IN
      TX_CANCEL_FLAG: "0", // 거래취소FLAG - 일반취소
      TOT_AMT: payInfo.totalAmount.toString(), // 총거래금액
      CASH_GBN: payInfo.proofKind === "CPR" ? CASH_GBN_BUSINESS : CASH_GBN_CONSUMER,
      CANCEL_CD: payInfo.proofCancelCode, // 취소사유코드
      OTX_DT: moment(payInfo.approvalDateTime).format(DATE_FORMAT_YYMMDD),
      AUTH_NO: payInfo.approvalNo, // 승인번호
      ENC_DATA: jsEncryptEncData,
    };
  }

  async _createViewCheckRequestBody(payInfo) {
    return {
      TERM_ID: payInfo.termId, // 단말기 ID
      PROC_CODE: PROC_CODE_VIEW_CHECK, // 전문코드 - 수표조회
      WORK_CODE: WORK_CODE_VIEW_CHECK, // 요청구분 - 수표조회
      CHK_TYPE: payInfo.chkType, // 수표종류
      CHK_NO: (payInfo.checkType ? payInfo.checkType : "").concat(payInfo.checkNo ? payInfo.checkNo : ""), // 권종/수표번호
      CHK_AMT: payInfo.totalAmount.toString(), // 수표금액
      CHK_DATE: payInfo.checkPubDate, // 수표발행일자
      CHK_ACC: payInfo.checkAccount, // 수표계좌번호
      CHK_ID: payInfo.chkId, // 주민등록번호
    };
  }

  async _createCardApprovalResult(response, payInfo) {
    console.log('card.payInfo.===>', payInfo);
    const responseToString = JSON.stringify(response, null, 2);
    const purchaseInfo = await this._convertPurchaseCodeToPurchaseInfo(response.AC_CODE);

    if (!purchaseInfo) {
      return {
        tid: payInfo.id,
        status: this.STATUS.APPROVED,
        approvalDateTime: moment(response.OTX_DT, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
        approvalNo: response.AUTH_NO ? response.AUTH_NO : payInfo.approvalNo,
        totalAmount: Number(payInfo.totalAmount),
        taxAmount: Number(payInfo.taxAmount),
        vatAmount: Number(payInfo.vatAmount),
        notaxAmount: Number(payInfo.notaxAmount),
        serviceAmount: Number(payInfo.serviceAmount),
        cardNo: response.CARD_BIN,
        divideTerm: Number(payInfo.divideTerm),
        purchaseName: response.AC_NAME,
        issueCompanyName: response.CC_NAME,
        merchantNo: response.MCHT_NO,
        saleNo: response.TRANS_NO,
        message: `${response.AC_NAME}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: responseToString,
        vanResponseData: responseToString,
        termId: response.TERM_ID
      };
    }

    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(response.OTX_DT, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response.AUTH_NO ? response.AUTH_NO : payInfo.approvalNo,
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      cardNo: response.CARD_BIN,
      divideTerm: Number(payInfo.divideTerm),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: response.AC_NAME,
      issueCompanyName: response.CC_NAME,
      merchantNo: response.MCHT_NO,
      saleNo: response.TRANS_NO,
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.TERM_ID
    };
  }

  _createCashReceiptApprovalResult(response, payInfo) {
    console.log('cash.payInfo.===>', payInfo);
    const responseToString = JSON.stringify(response, null, 2);
    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(response.OTX_DT, "YYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response.AUTH_NO ? response.AUTH_NO : payInfo.approvalNo,
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      proofKind: payInfo.proofKind,
      proofNo: response.CARD_BIN,
      saleNo: response.TRANS_NO,
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.TERM_ID
    };
  }

  /**
   * VAN 사 매입처 코드를 ERP 내 매입처 정보로 변환
   * @param purchaseCode
   * @return {Promise<*>}
   * @private
   */
  async _convertPurchaseCodeToPurchaseInfo(purchaseCode) {
    const placesOfCardPurchase = await GolfErpAPI.getPlacesOfCardPurchase("NHNKCP");

    const cardList = placesOfCardPurchase.map(
      ({ purchaseId, purchaseName, vanMappingList }) => {
        return {
          purchaseId: purchaseId,
          purchaseName: purchaseName,
          vanMapCode: vanMappingList[0].vanMapCode
        };
      });

    return cardList.find(confCard => confCard.vanMapCode === purchaseCode);
  }
}