import axios from "axios";
import jsonpAdapter from "@/utils/jsonpAdapter";

function generateCallbackName() {
  return "FN_SecureDataCallBack" + Math.round(100000 * Math.random());
}

function getScriptUrl(url, securePosStatus) {
  let scriptDATA =
    "SECU_DATA=[" + JSON.stringify(securePosStatus) + "]SECU_END";
  return url + "?" + scriptDATA;
}

export function checkDaemonApi(url) {
  const callbackName = generateCallbackName();

  // 상태요청 전문================================================
  const securePosStatus = {
    PROC_CODE: "T01",
    WORK_CODE: "0100",
    CALLBACK_NM: callbackName,
  };

  const requestUrl = getScriptUrl(url, securePosStatus);

  return axios({
    url: requestUrl,
    adapter: jsonpAdapter,
    callbackParamName: callbackName,
    timeout: 1000,
  });
}

export function approveApi(url, data) {
  const callbackName = generateCallbackName();

  // 카드요청 전문
  data.CALLBACK_NM = callbackName;

  const requestUrl = getScriptUrl(url, data);

  return axios({
    url: requestUrl,
    adapter: jsonpAdapter,
    callbackParamName: callbackName,
    timeout: 31000,
  });
}
