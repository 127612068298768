export default class VanProcessError extends Error {
  status = 'FAIL';
  originResponse;
  originResponseData;

  constructor(message, status, originResponse, originResponseData) {
    if (status === "SELFCANCEL") {
      message = message.replace("오류", "");
    }
    super(message);
    this.name = "VanProcessError";
    this.originResponse = originResponse;
    this.originResponseData = originResponseData;
    if (status) {
      this.status = status;
    }
  }
}
