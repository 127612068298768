import NotImplementError from "@/error/NotImplementError";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

export default class AbstractVanModule {
  host;
  port;
  DEFAULT_PROOF_NO = "0100001234";
  STATUS = {
    READY: 'READY',
    APPROVED: 'APPROVED',
    CANCELED: 'CANCELED',
    ALREADY_CANCELED: 'ALREADY_CANCELED',
    OK: 'OK',             // 수표 조회 성공
    FAIL: 'FAIL',
    ROLLBACK: 'ROLLBACK',
    PURCHASE_CODE_INVALID: 'PURCHASE_CODE_INVALID',
    SELFCANCEL: "SELFCANCEL"
  };

  constructor(port) {
    Object.freeze(this.STATUS);
    this.host = '127.0.0.1';
    this.port = port;
  }

  // eslint-disable-next-line no-unused-vars
  async cardApprove(payInfo) {
    throw new NotImplementError();
  }

  // eslint-disable-next-line no-unused-vars
  async cashReceiptApprove(payInfo) {
    throw new NotImplementError();
  }

  // eslint-disable-next-line no-unused-vars
  async cardCancel(payInfo) {
    throw new NotImplementError();
  }

  // eslint-disable-next-line no-unused-vars
  async cashReceiptCancel(payInfo) {
    throw new NotImplementError();
  }

  async rollback() {
    throw new NotImplementError();
  }

  async viewCheck() {
    throw new NotImplementError();
  }

  _getRequestUrl(){
    throw new NotImplementError();
  }
  async _updateTransactionBeforeRequest(tid, requestString) {
    await GolfErpAPI.putPayTransaction(tid, {status: 'REQUEST', vanRequestString: requestString});
  }
}
