import AbstractVanModule from "@/van/AbstractVanModule";
import VanProcessError from "@/error/van/VanProcessError";
import {checkDaemonApi, approveApi} from "@/van/bestpay/RequestBestPay";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";

const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

// 정상 코드들
// 0 - 정상
const SUCCESS_CODES = [0,"0","0000"];
const ALREADY_CANCELED_CODES = ["1",1];

// 요청구분
const WORK_CODE_CARD_APPROVE_NORMAL = "101R"; // 카드 승인 일반 요청
const WORK_CODE_CARD_CANCEL_NORMAL = "102R"; // 카드 취소 일반 요청
const WORK_CODE_CASH_APPROVE_NORMAL = "201R"; // 현금영수증 승인 일반 요청
const WORK_CODE_CASH_CANCEL_NORMAL = "202R"; // 현금영수증 취소 일반 요청
const WORK_CODE_VIEW_CHECK = "303R"; // 수표 조회 요청
const WORK_CODE_EASY_APPROVE_NORMAL = "1R"; // 간편결제 승인 일반 요청
const WORK_CODE_EASY_CANCEL_NORMAL = "2R"; // 간편결제 취소 일반 요청
const WORK_CODE_EASY_CHECK = "3R"; // 간편결제 카카오페이 조회 요청

// Keyin
const PROC_CODE_CASH_RECEIPT = "K"; // 현금영수증 Pos Enty Mode
const PROC_CODE_EASY_RECEIPT = "Q"; // 간편결제 Enty Mode
const PROC_CODE_TOKEN_RECEIPT = "P"; // 리더기 토큰 Enty Mode

// 거래자구분
const CASH_GBN_CONSUMER = "00"; // 소비자 소득공제
const CASH_GBN_BUSINESS = "01"; // 사업자 지출공제

const EASY_GBN_KAKAO = "801"; //카카오 거래구분

// 거래 URL
const URL_CODE_INIT = "VTR_APP_Init"; // app정상 확인
const URL_CODE_START = "VTR_APP_Start"; // app실행
const URL_CODE_NETWORKURL = "ApprovalServerSec"; // 승인, 취소 URL
const URL_CODE_APPROVAL_CARDTOKEN = "VTR_APP_GetCreditToken"; // 신용 승인 토큰 획득
const URL_CODE_CANCEL_CARDTOKEN = "VTR_APP_GetCreditCancelToken"; // 신용 승인 토큰 획득
const URL_CODE_APPROVAL_CASHTOKEN = "VTR_APP_GetCashToken"; // 현금영수증 승인 토큰 획득
const URL_CODE_CANCEL_CASHTOKEN = "VTR_APP_GetCashCancelToken"; // 현금영수증 c취소 토큰 획득
const URL_CODE_APPROVAL_EASYTOKEN = "VTR_APP_QrScanner"; // 바코드,QR코드 스캐너
const URL_CODE_APPROVAL_EASYTYPETOKEN = "GetMsgTypeFromBarcode"; // 바코드 데이터로 간편결제 구분

// 취소 사유코드
// const CANCEL_CD_APPROVAL = "0"; // 승인시
 const CANCEL_CD_CANCEL = "1"; // 거래취소
// const CANCEL_CD_ERROR = "2"; // 오류발급
// const CANCEL_CD_ETC = "3"; // 기타취소

export default class BestPayVanModule extends AbstractVanModule {
  responseReady
  failLogPrefix

  constructor(port) {
    super(port);
    this.responseReady = true;
  }

  _getRequestUrl() {
    return `https://${this.host}:${this.port}/`;
  }

  async cardApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;
    try {
      return await this._cardApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` ,e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` , e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cardCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;
    try {
      return await this._cardCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async easyCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;
    try {
      return await this._easyCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async easyApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";
    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }
    this.responseReady = false;
    try {
      return await this._easyApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` ,e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` , e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptApprove(payInfo) {
    this.failLogPrefix = "[결제요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptApprovalRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async cashReceiptCancel(payInfo) {
    this.failLogPrefix = "[결제취소요청실패]";

    if (!this.responseReady) {
      throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
    }

    this.responseReady = false;

    try {
      return await this._cashReceiptCancelRequest(payInfo);
    } catch (e) {
      if (e instanceof VanProcessError) {
        throw e;
      } else if (e instanceof Error) {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e.message);
      } else {
        throw new VanProcessError(`[${this.failLogPrefix}] 기타 오류 : ` + e);
      }
    } finally {
      this.responseReady = true;
    }
  }

  async viewCheck(payInfo) {
    this.failLogPrefix = "[수표조회 실패]";
    let requestBody = await this._createViewCheckRequestBody(payInfo);
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL, requestBody);

        if (SUCCESS_CODES.includes(response?.Auth_stat)) {
          const dateTime =response.Auth_date+response.Auth_time;
          return {
            tid: payInfo.id,
            status: this.STATUS.OK,
            approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
            saleNo: response.Trade_serial_no,
            vanResponseString: JSON.stringify(response, null, 2),
            vanResponseData: JSON.stringify(response, null, 2),
            termId: response.Term_id
          };
        } else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${response?.Msg1}`,
              "FAIL",
              JSON.stringify(response, null, 2),
              JSON.stringify(response, null, 2));
        }
      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _easyApprovalRequest(payInfo) {
    let requestBody;
    let resultInfo;
    let resultKakaoInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:
        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_EASYTOKEN);
        resultInfo = response;
        if(response?.ScanData != null){

          requestBody = this._createEasyTypeApprovalRequestBody(resultInfo?.ScanData);
          // eslint-disable-next-line no-case-declarations
          const { data: response, result:result } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_EASYTYPETOKEN,requestBody);
          resultInfo = Object.assign(resultInfo, response);

          if(SUCCESS_CODES.includes(result)) {
            //카카오 별도 승인 if
            if(resultInfo.msg_type == EASY_GBN_KAKAO){
              requestBody = this._createEasyKakaoCheckRequestBody(payInfo,resultInfo);
              await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
              //카카오 api 조회
              const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL,requestBody);
              resultKakaoInfo = response;

              if (SUCCESS_CODES.includes(response?.Auth_stat)) {
                //카카오 api 결제
                requestBody = this._createEasyKakaoApprovalRequestBody(payInfo,resultInfo,resultKakaoInfo);

                await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
                const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL,requestBody);
                if (SUCCESS_CODES.includes(response?.Auth_stat)) {
                  return await this._createEasyApprovalResult(response,payInfo,resultInfo);
                }else {
                  throw new VanProcessError(
                      `[${this.failLogPrefix}] ${response?.Msg1}`,
                      "FAIL",
                      JSON.stringify(response, null, 2),
                      JSON.stringify(response, null, 2));
                }
              }else {
                throw new VanProcessError(
                    `[${this.failLogPrefix}] ${response?.Msg1}`,
                    "FAIL",
                    JSON.stringify(response, null, 2),
                    JSON.stringify(response, null, 2));
              }

            }else {
              requestBody = this._createEasyApprovalRequestBody(payInfo,resultInfo);
              await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
              const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL,requestBody);
              if (SUCCESS_CODES.includes(response?.Auth_stat)) {
                return await this._createEasyApprovalResult(response,payInfo,resultInfo);
              }else {
                throw new VanProcessError(
                    `[${this.failLogPrefix}] ${response?.Msg1}`,
                    "FAIL",
                    JSON.stringify(response, null, 2),
                    JSON.stringify(response, null, 2));
              }
            }
          } else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }

        }else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${response?.Msg1}`,
              "FAIL",
              JSON.stringify(response, null, 2),
              JSON.stringify(response, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardApprovalRequest(payInfo) {
    //let requestBody = this._createCardApprovalRequestBody(payInfo);
    let requestBody = this._createTokenRequestBody(payInfo);
    let resultCardInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:

        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_CARDTOKEN, requestBody);
        resultCardInfo = response;

        if (SUCCESS_CODES.includes(response?.Resp_div)) {

          requestBody = this._createCardApprovalRequestBody(payInfo,resultCardInfo);
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL, requestBody);

          if (SUCCESS_CODES.includes(response?.Auth_stat)) {
            return await this._createCardApprovalResult(response, payInfo,resultCardInfo);
          }else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }
        } else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${response?.Msg1}`,
              "FAIL",
              JSON.stringify(response, null, 2),
              JSON.stringify(response, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cardCancelRequest(payInfo) {
    //let requestBody = this._createCardCancelRequestBody(payInfo);
    let requestBody = this._createTokenRequestBody(payInfo);
    let resultCardInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:

        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_CANCEL_CARDTOKEN, requestBody);
        resultCardInfo = response;

        if (SUCCESS_CODES.includes(response?.Resp_div)) {
          requestBody = this._createCardCancelRequestBody(payInfo,resultCardInfo);
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL, requestBody);
          if (SUCCESS_CODES.includes(response?.Auth_stat)) {
            let result = await this._createCardApprovalResult(response, payInfo,resultCardInfo);
            result.status = this.STATUS.CANCELED;
            return result;
          } else if (ALREADY_CANCELED_CODES.includes(response?.Auth_stat)) {
            let result = await this._createCardApprovalResult(response, payInfo,resultCardInfo);
            result.status = this.STATUS.ALREADY_CANCELED;
            return result;
          } else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }
        } else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${response?.Msg1}`,
              "FAIL",
              JSON.stringify(response, null, 2),
              JSON.stringify(response, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _easyCancelRequest(payInfo) {

    let requestBody;
    let resultInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:
        // eslint-disable-next-line no-case-declarations
        const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_EASYTOKEN);
        resultInfo = response;

        if(response?.ScanData != null){

          requestBody = this._createEasyTypeApprovalRequestBody(resultInfo?.ScanData);
          // eslint-disable-next-line no-case-declarations
          const { data: response, result:result } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_EASYTYPETOKEN,requestBody);
          resultInfo = Object.assign(resultInfo, response);

          if(SUCCESS_CODES.includes(result)) {
            requestBody = this._createEasyCancelRequestBody(payInfo,resultInfo);
            await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));
            const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL,requestBody);
            if (SUCCESS_CODES.includes(response?.Auth_stat)) {
              let result = await this._createEasyApprovalResult(response,payInfo,resultInfo);
              result.status = this.STATUS.CANCELED;
              return result;

            }else {
              throw new VanProcessError(
                  `[${this.failLogPrefix}] ${response?.Msg1}`,
                  "FAIL",
                  JSON.stringify(response, null, 2),
                  JSON.stringify(response, null, 2));
            }
          } else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }
        }else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${response?.Msg1}`,
              "FAIL",
              JSON.stringify(response, null, 2),
              JSON.stringify(response, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptApprovalRequest(payInfo) {
    //let requestBody = this._createCashReceiptApprovalRequestBody(payInfo);
    let requestBody = this._createTokenRequestBody(payInfo);
    let resultCashInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        if(payInfo.proofNo == null || payInfo.proofNo == ""){
          // eslint-disable-next-line no-case-declarations
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_APPROVAL_CASHTOKEN, requestBody);
          resultCashInfo = response;
        }else {
          resultCashInfo = this._createCashTokenInfo(payInfo);
        }

        if (SUCCESS_CODES.includes(resultCashInfo?.Resp_div)) {
          requestBody = this._createCashReceiptApprovalRequestBody(payInfo,resultCashInfo);
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL, requestBody);


          if (SUCCESS_CODES.includes(response?.Auth_stat)) {

            return await this._createCashReceiptApprovalResult(response, payInfo,resultCashInfo);
          } else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }
        } else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${resultCashInfo?.Msg1}`,
              "FAIL",
              JSON.stringify(resultCashInfo, null, 2),
              JSON.stringify(resultCashInfo, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _cashReceiptCancelRequest(payInfo) {
    //let requestBody = this._createCashReceiptCancelRequestBody(payInfo);
    let requestBody = this._createTokenRequestBody(payInfo);
    let resultCashInfo;
    let status = await this._checkDemonStatus();

    switch (status) {
      case 0:
        await this._updateTransactionBeforeRequest(payInfo.id, JSON.stringify(requestBody));

        if(payInfo.proofNo == null || payInfo.proofNo == ""){
        // eslint-disable-next-line no-case-declarations
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_CANCEL_CASHTOKEN, requestBody);
          resultCashInfo = response;
        }else {
          resultCashInfo = this._createCashTokenInfo(payInfo);
        }

        if (SUCCESS_CODES.includes(resultCashInfo?.Resp_div)) {
          requestBody = this._createCashReceiptCancelRequestBody(payInfo,resultCashInfo);
          const { data: response } = await approveApi(this._getRequestUrl()+URL_CODE_NETWORKURL, requestBody);

          if (SUCCESS_CODES.includes(response?.Auth_stat)) {

            return await this._createCashReceiptApprovalResult(response, payInfo,resultCashInfo);
          } else {
            throw new VanProcessError(
                `[${this.failLogPrefix}] ${response?.Msg1}`,
                "FAIL",
                JSON.stringify(response, null, 2),
                JSON.stringify(response, null, 2));
          }
        } else {
          throw new VanProcessError(
              `[${this.failLogPrefix}] ${resultCashInfo?.Msg1}`,
              "FAIL",
              JSON.stringify(resultCashInfo, null, 2),
              JSON.stringify(resultCashInfo, null, 2));
        }

      case "-99":
        throw new VanProcessError(`[${this.failLogPrefix}] 결제가 진행중입니다.`);
      default:
        throw new VanProcessError(`[${this.failLogPrefix}] 진행 상태를 다시 확인해 주십시오!!`);
    }
  }

  async _checkDemonStatus() {
    const rtn = await checkDaemonApi(this._getRequestUrl()+URL_CODE_INIT);
    if(SUCCESS_CODES.includes(rtn?.result)) {
      return rtn?.result;
    }else {
      const apirtn = await checkDaemonApi(this._getRequestUrl()+URL_CODE_START);
      return apirtn?.result;
    }
  }

  _createTokenRequestBody(payInfo) {
    return {
      "Term_div": PROC_CODE_TOKEN_RECEIPT,
      "Term_id": "",
      "Trade_serial_no": "",
      "m_Certify_no": "",
      "Van_index": "0",
      "Amount": payInfo.totalAmount.toString()
    };
  }

  _createEasyTypeApprovalRequestBody(borcode) {
    return {
      "borcode": borcode
    };
  }

  _createEasyKakaoCheckRequestBody(payInfo,resp) {
    return {
      "sbuffer": {
        "Msg_type": resp.msg_type.substr(0,2)+WORK_CODE_EASY_CHECK,
        "Cancel_reason": "",
        "Cancel_type": "",
        "Keyin": PROC_CODE_EASY_RECEIPT,
        "Barcode_no": resp.ScanData,
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Dc_amount": "",
        "Current_code": "",
        "Store_code": "",
        "Org_approval_no": "",
        "Org_auth_date": "",
        "Term_id": "",
        "Trade_serial_no": "",
        "Sub_simple_data1": "",
        "Sub_simple_data2": "",
        "Sub_simple_data3": "",
        "Vcode": "",
        "Esign_div": "0"
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createEasyKakaoApprovalRequestBody(payInfo,resp,kakaoinfo) {
    return {
      "sbuffer": {
        "Msg_type": resp.msg_type.substr(0,2)+WORK_CODE_EASY_APPROVE_NORMAL,
        "Cancel_reason": "",
        "Cancel_type": kakaoinfo.Notice1,
        "Keyin": PROC_CODE_EASY_RECEIPT,
        "Barcode_no": kakaoinfo.Barcode_no.trim() == "" ? resp.ScanData : kakaoinfo.Barcode_no.trim(),
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Dc_amount": kakaoinfo.Dc_Amount,
        "Current_code": "",
        "Store_code": "",
        "Org_approval_no": kakaoinfo.Approval_no,
        "Org_auth_date": "",
        "Term_id": "",
        "Trade_serial_no": "",
        "Sub_simple_data1": "",
        "Sub_simple_data2": "",
        "Sub_simple_data3": "",
        "Vcode": "",
        "Esign_div": payInfo.totalAmount > 50000 ? "1" : "0"
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createEasyApprovalRequestBody(payInfo,resp) {
    return {
      "sbuffer": {
        "Msg_type": resp.msg_type.substr(0,2)+WORK_CODE_EASY_APPROVE_NORMAL,
        "Cancel_reason": "",
        "Cancel_type": "",
        "Keyin": PROC_CODE_EASY_RECEIPT,
        "Barcode_no": resp.ScanData,
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Dc_amount": "",
        "Current_code": "",
        "Store_code": "",
        "Org_approval_no": "",
        "Org_auth_date": "",
        "Term_id": "",
        "Trade_serial_no": "",
        "Sub_simple_data1": "",
        "Sub_simple_data2": "",
        "Sub_simple_data3": "",
        "Vcode": "",
        "Esign_div": payInfo.totalAmount > 50000 ? "1" : "0"
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createEasyCancelRequestBody(payInfo,resp) {
    return {
      "sbuffer": {
        "Msg_type": resp.msg_type.substr(0,2)+WORK_CODE_EASY_CANCEL_NORMAL,
        "Cancel_reason": CANCEL_CD_CANCEL,
        "Cancel_type": "",
        "Keyin": PROC_CODE_EASY_RECEIPT,
        "Barcode_no": resp.ScanData,
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Dc_amount": "",
        "Current_code": "",
        "Store_code": "",
        "Org_approval_no": payInfo.approvalNo.toString(),
        "Org_auth_date": moment(payInfo.approvalDateTime.toString(), DATE_TIME_FORMAT).format("YYYYMMDD"),
        "Term_id": "",
        "Trade_serial_no": "",
        "Sub_simple_data1": "",
        "Sub_simple_data2": "",
        "Sub_simple_data3": "",
        "Vcode": "",
        "Esign_div": payInfo.totalAmount > 50000 ? "1" : "0"
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createCardApprovalRequestBody(payInfo,resp) {
    return {
      "sbuffer": {
        "Msg_type": WORK_CODE_CARD_APPROVE_NORMAL,
        "Cancel_reason": "",
        "Keyin": resp.Keyin,
        "Track_data": resp.Vt_data,
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Org_approval_no": "",
        "Org_auth_date": "",
        "Term_id": "",
        "Trade_serial_no": "",
        "Vcode": "",
        "Esign_div": payInfo.totalAmount > 50000 ? "1" : "0"
      },
        "perbuffer": {
        "bufferdata": ""
      },
        "emvbuffer": {
        "bufferdata": ""
      },
        "subbuffer": {
        "Remark_Count": "",
            "Remark_01": "",
            "Remark_02": "",
            "Remark_03": "",
            "Remark_04": "",
            "Remark_05": "",
            "Remark_06": "",
            "Remark_07": "",
            "Remark_08": "",
            "Remark_09": "",
            "Remark_10": "",
            "Remark_11": "",
            "Remark_12": ""
      },
        "signbuffer": {
        "bufferdata": ""
      },
        "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createCardCancelRequestBody(payInfo,resp) {
    return {
      "sbuffer": {
        "Msg_type": WORK_CODE_CARD_CANCEL_NORMAL,
        "Cancel_reason": CANCEL_CD_CANCEL,
        "Keyin": resp.Keyin,
        "Track_data": resp.Vt_data,
        "Halbu": payInfo.divideTerm.toString().padStart(2, "0"),
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Org_approval_no": payInfo.approvalNo.toString(),
        "Org_auth_date": moment(payInfo.approvalDateTime.toString(), DATE_TIME_FORMAT).format("YYYYMMDD"),
        "Term_id": "",
        "Trade_serial_no": "",
        "Vcode": "",
        "Esign_div": payInfo.totalAmount > 50000 ? "1" : "0"
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };

  }

  _createCashReceiptApprovalRequestBody(payInfo,resp) {

    return {
       "sbuffer": {
          "Msg_type": WORK_CODE_CASH_APPROVE_NORMAL,
          "Cancel_reason": "",
          "Keyin": resp.Keyin,
          "Track_data": resp.Vt_data,
          "Trader_div": payInfo.proofKind === "CPR" ? CASH_GBN_BUSINESS : CASH_GBN_CONSUMER,
          "Pay_amount": payInfo.notaxAmount.toString(),
          "Tax": payInfo.vatAmount.toString(),
          "Svrcharge": payInfo.serviceAmount.toString(),
          "Amount": payInfo.totalAmount.toString(),
          "Org_approval_no": "",
          "Org_auth_date": "",
          "Term_id": "",
          "Trade_serial_no": "",
          "Vcode": ""
        },
        "perbuffer": {
          "bufferdata": ""
        },
        "emvbuffer": {
          "bufferdata": ""
        },
        "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };
  }

  _createCashReceiptCancelRequestBody(payInfo,resp) {

    return {
      "sbuffer": {
        "Msg_type": WORK_CODE_CASH_CANCEL_NORMAL,
        "Cancel_reason": payInfo.proofCancelCode,
        "Keyin": resp.Keyin,
        "Track_data": resp.Vt_data,
        "Trader_div": payInfo.proofKind === "CPR" ? CASH_GBN_BUSINESS : CASH_GBN_CONSUMER,
        "Pay_amount": payInfo.notaxAmount.toString(),
        "Tax": payInfo.vatAmount.toString(),
        "Svrcharge": payInfo.serviceAmount.toString(),
        "Amount": payInfo.totalAmount.toString(),
        "Org_approval_no": payInfo.approvalNo,
        "Org_auth_date": moment(payInfo.approvalDateTime.toString(), DATE_TIME_FORMAT).format("YYYYMMDD"),
        "Term_id": "",
        "Trade_serial_no": "",
        "Vcode": ""
      },
      "perbuffer": {
        "bufferdata": ""
      },
      "emvbuffer": {
        "bufferdata": ""
      },
      "subbuffer": {
        "Remark_Count": "",
        "Remark_01": "",
        "Remark_02": "",
        "Remark_03": "",
        "Remark_04": "",
        "Remark_05": "",
        "Remark_06": "",
        "Remark_07": "",
        "Remark_08": "",
        "Remark_09": "",
        "Remark_10": "",
        "Remark_11": "",
        "Remark_12": ""
      },
      "signbuffer": {
        "bufferdata": ""
      },
      "resbuffer": {
        "bufferdata": ""
      }
    };

  }

  async _createViewCheckRequestBody(payInfo) {
    return {
        "sbuffer": {
        "Msg_type": WORK_CODE_VIEW_CHECK,               //전문구분
            "Chech_div": payInfo.chkType, //수표구분
            "Chech_number": payInfo.chkNum,           //수표번호
            "Issue_bank_code": payInfo.chkBank,        //발행은행콛,
            "Issue_branch_code": payInfo.chkBrch,      //발행은행지점
            "Check_kind_code": payInfo.checkType,      //권종코드
            "Check_amount": payInfo.totalAmount.toString(),     //수표금액
            "Issue_date": payInfo.checkPubDate,             //발행일
            "Account_serial": payInfo.checkAccount,         //계좌일련번호
            "Term_id": "",    // 단말기 ID
            "Trade_serial_no": ""         //거래일련번호
      },
        "perbuffer": {
        "bufferdata": ""
      },
        "emvbuffer": {
        "bufferdata": ""
      },
        "subbuffer": {
        "Remark_Count": "",
            "Remark_01": "",
            "Remark_02": "",
            "Remark_03": "",
            "Remark_04": "",
            "Remark_05": "",
            "Remark_06": "",
            "Remark_07": "",
            "Remark_08": "",
            "Remark_09": "",
            "Remark_10": "",
            "Remark_11": "",
            "Remark_12": ""
      },
        "signbuffer": {
        "bufferdata": ""
      },
        "resbuffer": {
        "bufferdata": ""
      }
    };
  }//_createViewCheckRequestBody end

  async _createCardApprovalResult(response, payInfo,resultCardInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const purchaseInfo = await this._convertPurchaseCodeToPurchaseInfo(response.Acquire_code);
    const dateTime = response.Auth_date+response.Auth_time;
    const cardNo = resultCardInfo.Vt_data.substr(0,6)+"**********";
    if (!purchaseInfo) {
      return {
        tid: payInfo.id,
        status: this.STATUS.APPROVED,
        approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
        approvalNo: response.Approval_no ? response.Approval_no : payInfo.approvalNo,
        totalAmount: Number(payInfo.totalAmount),
        taxAmount: Number(payInfo.taxAmount),
        vatAmount: Number(payInfo.vatAmount),
        notaxAmount: Number(payInfo.notaxAmount),
        serviceAmount: Number(payInfo.serviceAmount),
        cardNo: cardNo,
        divideTerm: Number(payInfo.divideTerm),
        purchaseName: response.Acquire_name,
        issueCompanyName: response.Issue_name,
        merchantNo: response.Merchant_no,
        saleNo: response.Trade_serial_no,
        message: `${response.Issue_name}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: responseToString,
        vanResponseData: responseToString,
        termId: response.Term_id
      };
    }

    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),   //승인일시
      approvalNo: response.Approval_no ? response.Approval_no : payInfo.approvalNo,         //승인번호
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      cardNo: cardNo,                      //카드번호
      divideTerm: Number(payInfo.divideTerm),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: purchaseInfo.purchaseName,         //매입처
      issueCompanyName: response.Issue_name,          //발급사명
      merchantNo: response.Merchant_no,             //가맹번호
      saleNo: response.Trade_serial_no,              //거래번호
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.Term_id
    };
  }

  _createCashReceiptApprovalResult(response, payInfo,resultCashInfo) {
    const responseToString = JSON.stringify(response, null, 2);
    const dateTime = response.Auth_date+response.Auth_time;

    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
      approvalNo: response.Approval_no ? response.Approval_no : payInfo.approvalNo,
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      proofKind: payInfo.proofKind,
      proofNo: Number(resultCashInfo.Vt_length) < 13 ?  resultCashInfo.Vt_data.replace(/(?<=.{3})./, "*").replace(/(?<=.{4})./, "*").replace(/(?<=.{5})./, "*").replace(/(?<=.{6})./, "*") : resultCashInfo.Vt_data.replace(/(?<=.{6})./gi, "*"),
      saleNo: response.Trade_serial_no,
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.Term_id
    };
  }

  async _createEasyApprovalResult(response, payInfo,resultInfo) {

    const responseToString = JSON.stringify(response, null, 2);
    const purchaseInfo = await this._convertPurchaseCodeToPurchaseInfo(response.Acquire_code);
    const dateTime = response.Auth_date+response.Auth_time;
    const cardNo = resultInfo.ScanData.substr(0,6)+"**********";
    if (!purchaseInfo) {
      return {
        tid: payInfo.id,
        status: this.STATUS.APPROVED,
        approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),
        approvalNo: response.Approval_no ? response.Approval_no : payInfo.approvalNo,
        totalAmount: Number(payInfo.totalAmount),
        taxAmount: Number(payInfo.taxAmount),
        vatAmount: Number(payInfo.vatAmount),
        notaxAmount: Number(payInfo.notaxAmount),
        serviceAmount: Number(payInfo.serviceAmount),
        cardNo: cardNo,
        divideTerm: Number(payInfo.divideTerm),
        purchaseName: response.Acquire_name,
        issueCompanyName: response.Issue_name,
        merchantNo: response.Merchant_no,
        saleNo: response.Trade_serial_no,
        message: `${response.Issue_name}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: responseToString,
        vanResponseData: responseToString,
        termId: response.Term_id
      };
    }

    return {
      tid: payInfo.id,
      status: this.STATUS.APPROVED,
      approvalDateTime: moment(dateTime, "YYYYMMDDHHmmss").format(DATE_TIME_FORMAT),   //승인일시
      approvalNo: response.Approval_no ? response.Approval_no : payInfo.approvalNo,         //승인번호
      totalAmount: Number(payInfo.totalAmount),
      taxAmount: Number(payInfo.taxAmount),
      vatAmount: Number(payInfo.vatAmount),
      notaxAmount: Number(payInfo.notaxAmount),
      serviceAmount: Number(payInfo.serviceAmount),
      cardNo: cardNo,                      //카드번호
      divideTerm: Number(payInfo.divideTerm),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: purchaseInfo.purchaseName,         //매입처
      issueCompanyName: response.Issue_name,          //발급사명
      merchantNo: response.Merchant_no,             //가맹번호
      saleNo: response.Trade_serial_no,              //거래번호
      vanResponseString: responseToString,
      vanResponseData: responseToString,
      termId: response.Term_id
    };
  }

  _createCashTokenInfo(payInfo) {
    return {
      "Keyin": PROC_CODE_CASH_RECEIPT,
      "Resp_div" : "0",
      "Vt_data": payInfo.proofNo,
      "Vt_length" : payInfo.proofNo.length
    };
  }

  /**
   * VAN 사 매입처 코드를 ERP 내 매입처 정보로 변환
   * @param purchaseCode
   * @return {Promise<*>}
   * @private
   */
  async _convertPurchaseCodeToPurchaseInfo(purchaseCode) {
    const placesOfCardPurchase = await GolfErpAPI.getPlacesOfCardPurchase("BEST_PAY");

    const cardList = placesOfCardPurchase.map(
        ({ purchaseId, purchaseName, vanMappingList }) => {
          return {
            purchaseId: purchaseId,
            purchaseName: purchaseName,
            vanMapCode: vanMappingList[0].vanMapCode
          };
        });
    return cardList.find(confCard => confCard.vanMapCode === purchaseCode);
  }
}