import AbstractVanModule from "@/van/AbstractVanModule";
import {requestToWebSocket} from "@/van/nice/RequestNice";
import moment from "moment";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import VanProcessError from "@/error/van/VanProcessError";


const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
// const DATE_FORMAT = 'YYYY-MM-DD';

// 구분자
const FS = '\x1C';
const BEL = '\x07';
const EMPTY = '';
// 거래 구분
const PROCESS_CODE = {
  APPROVAL: '0200',     // 승인
  CANCEL: '0420',       // 취소
  LATEST_CANCEL: '0421' // 망상취소
  // 직전취소(0201)
};

// 거래 유형
const PAY_DIVISION = {
  CARD: '10',         // 카드
  CASH_RECEIPT: '21',  // 현금영수증
  CHECK: '20'         // 수표
};

// 현금영수증 Keyin여부
// 신용거래 Fallback 여부
// 신용 거래 일련번호 취소 사용 여부
const TYPE = {
  CARD: 'C',                            // 카드
  KEY_IN: 'K',                          // KEY IN
  TOUCH: 'T',                           // 터치
  FALLBACK: 'F',                        // 카드 Fallback
  POS_INPUT: 'P',                       // 포스 입력
  CANCEL_BY_SALE_CODE_WITHOUT_SIGN: 'N' // 거래일련번호 신용취소(카드)
};

// 현금영수증 발급 구분
const PROOF_KIND = {
  FIT: '01',
  CPR: '02',
  VOL: '03',
};

// 결과 코드
const RESPONSE_CODE = {
  SUCCESS: '0000',
  ALREADY_CANCELED: ['2003', '6003']
};


Object.freeze(PROCESS_CODE);
Object.freeze(PAY_DIVISION);
Object.freeze(TYPE);
Object.freeze(PROOF_KIND);
Object.freeze(RESPONSE_CODE);

export default class NiceVanModule extends AbstractVanModule {
  constructor(port) {
    super(port);
  }

  async cardApprove(payInfo) {
    const requestBody = this._createCardApprovalRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, requestBody);
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    return this._convertCardApprovalResult(payInfo, response);
  }

  async cardCancel(payInfo) {
    const requestBody = this._createCardCancelRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, requestBody);
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    return this._convertCardCancelResult(payInfo, response);
  }

  async cashReceiptApprove(payInfo) {
    const requestBody = await this._createCashReceiptApprovalRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, requestBody);
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    return this._convertCashReceiptApprovalResult(payInfo, response);
  }

  async cashReceiptCancel(payInfo) {
    const requestBody = await this._createCashReceiptCancelRequestBody(payInfo);
    await this._updateTransactionBeforeRequest(payInfo.id, requestBody);
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    return this._convertCashReceiptCancelResult(payInfo, response);
  }

  async rollback(payInfo) {
    if (payInfo.payDivision === 'CASH') {
      await this.cashReceiptCancel(payInfo);
      return {
        status: this.STATUS.ROLLBACK
      };
    }

    const requestBody = await this._createRollbackRequestBody(payInfo);
    await requestToWebSocket(this._getRequestUrl(), requestBody);
    return {
      status: this.STATUS.ROLLBACK
    };
  }

  async viewCheck(checkInfo) {
    const requestBody = await this._createCheckRequestBody(checkInfo);
    const response = await requestToWebSocket(this._getRequestUrl(), requestBody);
    const status = this._getStatusAndMessageByCheckResponse(response);
    return {
      status: status
    };
  }

  /**
   *
   * @return {string}
   * @private
   */
  _getRequestUrl() {
    return `${this.host}:${this.port}`;
  }


  /**
   *
   * @param payInfo
   * @return {string}
   * @private
   */
  _createCardApprovalRequestBody(payInfo) {
    let request = [
      PROCESS_CODE.APPROVAL,    // 거래구분
      PAY_DIVISION.CARD,        // 거래유형
      TYPE.CARD,                // 카드 여부
      payInfo.totalAmount,      // 거래금액
      payInfo.vatAmount,        // 부가세
      payInfo.serviceAmount,    // 봉사료
      payInfo.divideTerm.toString().padStart(2, "0"),       // 할부
      EMPTY,                    // 승인번호(취소시 원거래 승인번호)
      EMPTY,                    // 원거래일자(취소시 원거래일자)
      payInfo.termId,           // 승인CATID (다중사업자 결제시 승인CATID 설정)
      EMPTY,                    // 공백
      EMPTY,                    // 공백
      EMPTY,                    // 현금영수증 식별 번호 또는 취소시 거래 일련 번호
      payInfo.notaxAmount,      // 면세금액
      EMPTY,                    // 납세자번호 필드 구분자
      EMPTY,                    // 납세자번호 필드 Data
      EMPTY,                    // 전문관리번호
      EMPTY,                    // Filler
      EMPTY,                    // 서명 패드 표시 금액
      EMPTY,                    // SignData
      EMPTY,                    // 부가정보여부(머니플러스 사용시)
      EMPTY,                    // 가맹점코드(머니플러스 사용시)
      EMPTY,                    // 부가정보(머니플러스 사용시)
      EMPTY,                    // Filler2(머니플러스 사용시)
    ].join(FS);

    request = "NICEVCAT".concat(BEL).concat(request).concat(BEL);

    return request;
  }

  /**
   *
   * @param payInfo
   * @return {string}
   * @private
   */
  _createCardCancelRequestBody(payInfo) {
    let request = [
      PROCESS_CODE.CANCEL,      // 거래구분
      PAY_DIVISION.CARD,        // 거래유형
      TYPE.CARD,                // 카드 여부
      payInfo.totalAmount,      // 거래금액
      payInfo.vatAmount,        // 부가세
      payInfo.serviceAmount,    // 봉사료
      payInfo.divideTerm.toString().padStart(2, "0"), // 할부기간 또는 현금영수증 구분
      payInfo.approvalNo,       // 승인번호(취소시 원거래 승인번호)
      moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format('YYMMDD'),  // 원거래일자(취소시 원거래일자)
      payInfo.termId,           // 승인CATID (다중사업자 결제시 승인CATID 설정)
      EMPTY,                    // 공백
      EMPTY,                    // 공백
      EMPTY,                    // 현금영수증 식별 번호 또는 취소시 거래 일련 번호
      payInfo.notaxAmount,      // 면세금액
      EMPTY,                    // 납세자번호 필드 구분자
      EMPTY,                    // 납세자번호 필드 Data
      EMPTY,                    // 전문관리번호
      EMPTY,                    // Filler
      EMPTY,                    // 서명 패드 표시 금액
      EMPTY,                    // SignData
      EMPTY,                    // 부가정보여부(머니플러스 사용시)
      EMPTY,                    // 가맹점코드(머니플러스 사용시)
      EMPTY,                    // 부가정보(머니플러스 사용시)
      EMPTY,                    // Filler2(머니플러스 사용시)
    ].join(FS);

    request = "NICEVCAT".concat(BEL).concat(request).concat(BEL);

    return request;
  }

  _createCashReceiptApprovalRequestBody(payInfo) {
    const proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);

    // 현금 영수증 카드 증빙이 아니고
    // 식별번호가 있을 경우 포스 입력으로, 그 이외에는 key패드를 이용한 입력
    let type = payInfo.cardProof
      ? TYPE.CARD
      : proof.proofNo
        ? TYPE.POS_INPUT
        : TYPE.KEY_IN;

    let request = [
      PROCESS_CODE.APPROVAL,      // 거래구분
      PAY_DIVISION.CASH_RECEIPT,  // 거래유형
      type,                       // 현금영수증 Keyin 여부
      payInfo.totalAmount,        // 거래금액
      payInfo.vatAmount,          // 부가세
      payInfo.serviceAmount,      // 봉사료
      proof.proofKind,            // 할부기간 또는 현금영수증 구분
      EMPTY,                      // 승인번호(취소시 원거래 승인번호)
      EMPTY,                      // 원거래일자(취소시 원거래일자)
      payInfo.termId,             // 승인CATID (다중사업자 결제시 승인CATID 설정)
      EMPTY,                      // 공백
      EMPTY,                      // 공백
      proof.proofNo,              // 현금영수증 식별 번호 또는 취소시 거래 일련 번호
      payInfo.notaxAmount,        // 면세금액
      EMPTY,                      // 납세자번호 필드 구분자
      EMPTY,                      // 납세자번호 필드 Data
      EMPTY,                      // 전문관리번호
      EMPTY,                      // Filler
      EMPTY,                      // 서명 패드 표시 금액
      EMPTY,                      // SignData
      EMPTY,                      // 부가정보여부(머니플러스 사용시)
      EMPTY,                      // 가맹점코드(머니플러스 사용시)
      EMPTY,                      // 부가정보(머니플러스 사용시)
      EMPTY,                      // Filler2(머니플러스 사용시)
    ].join(FS);

    request = "NICEVCAT".concat(BEL).concat(request).concat(BEL);

    return request;
  }

  _createCashReceiptCancelRequestBody(payInfo) {
    const proof = this._convertToProof(payInfo.proofNo, payInfo.proofKind);

    // 현금 영수증 카드 증빙이 아니고
    // 식별번호가 있을 경우 포스 입력으로, 그 이외에는 key패드를 이용한 입력
    let type = payInfo.cardProof
      ? TYPE.CARD
      : proof.proofNo
        ? TYPE.POS_INPUT
        : TYPE.KEY_IN;

    let request = [
      PROCESS_CODE.CANCEL,      // 거래구분
      PAY_DIVISION.CASH_RECEIPT,  // 거래유형
      type,                     // 현금영수중 Keyin 여부
      payInfo.totalAmount,      // 거래금액
      payInfo.vatAmount,        // 부가세
      payInfo.serviceAmount,    // 봉사료
      proof.proofKind,          // 할부기간 또는 현금영수증 구분
      payInfo.approvalNo,       // 승인번호(취소시 원거래 승인번호)
      moment(payInfo.approvalDateTime, DATE_TIME_FORMAT).format('YYMMDD'),  // 원거래일자(취소시 원거래일자)
      payInfo.termId,           // 승인CATID (다중사업자 결제시 승인CATID 설정)
      EMPTY,                    // 공백
      EMPTY,                    // 공백
      proof.proofNo,            // 현금영수증 식별 번호 또는 취소시 거래 일련 번호
      payInfo.notaxAmount,      // 면세금액
      EMPTY,                    // 납세자번호 필드 구분자
      EMPTY,                    // 납세자번호 필드 Data
      EMPTY,                    // 전문관리번호
      EMPTY,                    // Filler
      EMPTY,                    // 서명 패드 표시 금액
      EMPTY,                    // SignData
      EMPTY,                    // 부가정보여부(머니플러스 사용시)
      EMPTY,                    // 가맹점코드(머니플러스 사용시)
      EMPTY,                    // 부가정보(머니플러스 사용시)
      EMPTY,                    // Filler2(머니플러스 사용시)
    ].join(FS);

    request = "NICEVCAT".concat(BEL).concat(request).concat(BEL);

    return request;
  }

  _createRollbackRequestBody(payInfo) {
    return [
      PROCESS_CODE.LATEST_CANCEL,
      PAY_DIVISION.CARD,
      TYPE.CARD,
      payInfo.totalAmount,
      payInfo.vatAmount,
      payInfo.serviceAmount,
      payInfo.divideTerm.toString().padStart(2, "0"),       // 할부기간 또는 현금영수증 구분
      EMPTY,                    // 취소시 원거래 승인번호
      EMPTY,                    // 취소시 원거래일자
      payInfo.termId,           // 승인CATID (다중사업자 결제시 승인CATID 설정)
      EMPTY,                    // 공백
      EMPTY,                    // 공백
      EMPTY,                    // 현금영수증 식별 번호 또는 취소시 거래 일련 번호
      payInfo.notaxAmount,
      EMPTY,                    // 공백
      EMPTY,                    // 공백
      EMPTY,                    // 공백
    ].join(FS);
  }

  _createCheckRequestBody(checkInfo) {
    let request = [
      PROCESS_CODE.APPROVAL,      // 거래구분(승인:0200)
      PAY_DIVISION.CHECK,         // 거래유형(수표조회:20)
      TYPE.KEY_IN,                // WCC(Keyin:K)
      '0' + checkInfo.chkType,    // 수표종류
      checkInfo.checkType,        // 수표권종
      checkInfo.checkNo,          // 수표번호
      checkInfo.checkPubDate,     // 발행일
      checkInfo.chkId ? checkInfo.chkId.replaceAll("-","") : "",  // 주민등록번호(수표 발행자의 주민 번호, 자기앞수표 조회는 SPACES)
      checkInfo.totalAmount,      // 수표금액
      checkInfo.checkAccount,     // 계좌일련번호
      EMPTY,                      // 부가정보여부
      EMPTY,                      // 가맹점코드
      EMPTY,                      // 부가정보
      EMPTY,                      // Filler2
    ].join(FS);

    request = "NICEVCAT".concat(BEL).concat(request).concat(BEL);

    return request;
  }
  /**
   *
   * @param {{}} payInfo
   * @param {{data: Array, originResponse: string}}response
   * @return {{}}
   * @private
   */
  async _convertCardApprovalResult(payInfo, response) {
    this._checkApprovalResponseCode(response);
    const purchaseInfo = await this._requestPurchaseInfo(response.data[11]);
    if (!purchaseInfo) {
      return {
        status: this.STATUS.PURCHASE_CODE_INVALID,
        approvalNo: response.data[7].trim(),
        approvalDateTime: moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
        totalAmount: payInfo.totalAmount,
        taxAmount: payInfo.taxAmount,
        vatAmount: payInfo.vatAmount,
        notaxAmount: payInfo.notaxAmount,
        serviceAmount: payInfo.serviceAmount,
        cardNo: response.data[17].trim(),
        divideTerm: Number(response.data[6]),
        purchaseName: response.data[12],
        issueCompanyName: response.data[10].trim(),
        merchantNo: response.data[13].trim(),
        termId: response.data[14].trim(),
        salesNo: response.data[20].trim(),
        message: `${response.data[12].trim()}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: response.originResponse,
        vanResponseData: JSON.stringify(response.data, null, 2)
      };
    }

    return {
      status: this.STATUS.APPROVED,
      approvalNo: response.data[7].trim(),
      approvalDateTime: moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
      totalAmount: payInfo.totalAmount,
      taxAmount: payInfo.taxAmount,
      vatAmount: payInfo.vatAmount,
      notaxAmount: payInfo.notaxAmount,
      serviceAmount: payInfo.serviceAmount,
      cardNo: response.data[17].trim(),
      divideTerm: Number(response.data[6]),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: response.data[12],
      issueCompanyName: response.data[10].trim(),
      merchantNo: response.data[13].trim(),
      termId: response.data[14].trim(),
      salesNo: response.data[20].trim(),
      vanResponseString: response.originResponse,
      vanResponseData: JSON.stringify(response.data, null, 2)
    };
  }

  async _convertCardCancelResult(payInfo, response) {
    const status = this._getStatusByCancelResponse(response);
    const purchaseInfo = await this._requestPurchaseInfo(response.data[11]);
    if (!purchaseInfo) {
      return {
        status: status,
        approvalNo: status === this.STATUS.ALREADY_CANCELED ? payInfo.approvalNo : response.data[7].trim(),
        approvalDateTime: status === this.STATUS.ALREADY_CANCELED ? moment().format(DATE_TIME_FORMAT) : moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
        totalAmount: payInfo.totalAmount,
        taxAmount: payInfo.taxAmount,
        vatAmount: payInfo.vatAmount,
        notaxAmount: payInfo.notaxAmount,
        serviceAmount: payInfo.serviceAmount,
        cardNo: response.data[17].trim(),
        divideTerm: Number(response.data[6]),
        purchaseName: response.data[12],
        issueCompanyName: response.data[10].trim(),
        merchantNo: response.data[13].trim(),
        termId: response.data[14].trim(),
        salesNo: response.data[20].trim(),
        message: `${response.data[12].trim()}  매입처 코드를 확인바랍니다(전산팀 문의)!`,
        vanResponseString: response.originResponse,
        vanResponseData: JSON.stringify(response.data, null, 2)
      };
    }

    return {
      status: status,
      approvalNo: status === this.STATUS.ALREADY_CANCELED ? payInfo.approvalNo : response.data[7].trim(),
      approvalDateTime: status === this.STATUS.ALREADY_CANCELED ? moment().format(DATE_TIME_FORMAT) : moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
      totalAmount: payInfo.totalAmount,
      taxAmount: payInfo.taxAmount,
      vatAmount: payInfo.vatAmount,
      notaxAmount: payInfo.notaxAmount,
      serviceAmount: payInfo.serviceAmount,
      cardNo: response.data[17].trim(),
      divideTerm: Number(response.data[6]),
      purchaseId: purchaseInfo.purchaseId,
      purchaseName: response.data[12],
      issueCompanyName: response.data[10].trim(),
      merchantNo: response.data[13].trim(),
      termId: response.data[14].trim(),
      salesNo: response.data[20].trim(),
      vanResponseString: response.originResponse,
      vanResponseData: JSON.stringify(response.data, null, 2)
    };
  }

  /**
   *
   * @param {{}} payInfo
   * @param {{data: Array, originResponse: string}} response
   * @return {{}}
   * @private
   */
  async _convertCashReceiptApprovalResult(payInfo, response) {
    this._checkApprovalResponseCode(response);

    return {
      status: this.STATUS.APPROVED,
      approvalNo: response.data[7].trim(),
      approvalDateTime: moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
      totalAmount: payInfo.totalAmount,
      taxAmount: payInfo.taxAmount,
      vatAmount: payInfo.vatAmount,
      notaxAmount: payInfo.notaxAmount,
      serviceAmount: payInfo.serviceAmount,
      proofKind: this._convertProofKind(response.data[6]),
      proofNo: response.data[17].trim(),
      printMessage: response.data[16].trim(),
      termId: response.data[14].trim(),
      salesNo: response.data[20].trim(),
      vanResponseString: response.originResponse,
      vanResponseData: JSON.stringify(response.data, null, 2)
    };
  }

  async _convertCashReceiptCancelResult(payInfo, response) {
    const status = this._getStatusByCancelResponse(response);

    return {
      status: status,
      approvalNo: status === this.STATUS.ALREADY_CANCELED ? payInfo.approvalNo : response.data[7].trim(),
      approvalDateTime: moment(response.data[8].trim(), 'YYMMDDHHmmss').format(DATE_TIME_FORMAT),
      totalAmount: payInfo.totalAmount,
      taxAmount: payInfo.taxAmount,
      vatAmount: payInfo.vatAmount,
      notaxAmount: payInfo.notaxAmount,
      serviceAmount: payInfo.serviceAmount,
      proofKind: this._convertProofKind(response.data[6]),
      proofNo: response.data[17].trim(),
      printMessage: response.data[16].trim(),
      termId: response.data[14].trim(),
      salesNo: response.data[20].trim(),
      vanResponseString: response.originResponse,
      vanResponseData: JSON.stringify(response.data, null, 2)
    };
  }

  async _requestPurchaseInfo(purchaseCode) {
    const placesOfCardPurchase = await GolfErpAPI.getPlacesOfCardPurchase("NICE");

    const cardList = placesOfCardPurchase.map(
      ({ purchaseId, purchaseName, vanMappingList }) => {
        return {
          purchaseId: purchaseId,
          purchaseName: purchaseName,
          vanMapCode: vanMappingList[0].vanMapCode
        };
      });
    return cardList.find(confCard => confCard.vanMapCode === purchaseCode);
  }

  /**
   *
   * @param {string} proofNo
   * @param {string} proofKind
   * @return {{proofNo: string, proofKind: string}}
   * @private
   */
  _convertToProof(proofNo, proofKind) {
    switch (proofKind) {
      case "FIT" : // 소비자소득공제
        return { proofNo : proofNo, proofKind: PROOF_KIND.FIT };
      case "CPR" : // 사업자지출증빙
        return { proofNo : proofNo, proofKind: PROOF_KIND.CPR };
      case "VOL" : // 자진발급
        return { proofNo : this.DEFAULT_PROOF_NO, proofKind: PROOF_KIND.VOL };
      default:
        throw new VanProcessError('현금영수증 증빙구분을 확인할 수 없습니다.');
    }
  }

  _convertProofKind(proofKind) {
    switch (proofKind.trim()) {
      case '01':
        return 'FIT';
      case '02':
        return 'CPR';
      case '03':
        return 'VOL';
      default:
        throw new VanProcessError('현금영수증 증빙구분을 확인할 수 없습니다.');
    }
  }

  /**
   *
   * @param {{data: Array, originResponse: string}} response
   * @private
   */
  _checkApprovalResponseCode(response) {
    if (response.data[2] !== RESPONSE_CODE.SUCCESS) {
      throw new VanProcessError(response.data[16].trim(), this.STATUS.FAIL, response.originResponse, JSON.stringify(response.data, null, 2));
    }
  }

  /**
   *
   * @param {{data: Array, originResponse: string}} cancelResponse
   * @return {string}
   * @private
   */
  _getStatusByCancelResponse(cancelResponse) {
    if (cancelResponse.data[2] === RESPONSE_CODE.SUCCESS) {
      return this.STATUS.CANCELED;
    } else if (RESPONSE_CODE.ALREADY_CANCELED.some(value => cancelResponse.data[2] === value)) {
      return this.STATUS.ALREADY_CANCELED;
    } else {
      throw new VanProcessError(cancelResponse.data[16].trim(), this.STATUS.FAIL, cancelResponse.originResponse, JSON.stringify(cancelResponse.data, null, 2));
    }
  }

  /**
   *
   * @param {{data: Array, originResponse: string}} checkResponse
   * @return {string}
   * @private
   */
  _getStatusAndMessageByCheckResponse(checkResponse) {
    if (checkResponse.data[2] === RESPONSE_CODE.SUCCESS) {
      return this.STATUS.OK;
    } else {
      throw new VanProcessError(checkResponse.data[4].trim(), this.STATUS.FAIL, checkResponse.originResponse, JSON.stringify(checkResponse.data, null, 2));
    }
  }
}
