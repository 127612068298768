import $ from "jquery";

export async function checkDaemonApi(url, reqDiv) {
  // const callback = "jsonp" + new Date().getTime();

  let requestBody = "AP" + String.fromCharCode(2) + "0003S2R" + String.fromCharCode(3) + String.fromCharCode(13);

  return $.ajax(url, {
    type: "POST",
    dataType: "jsonp",
    data: {
      // REQ: "C0"
      REQ: (reqDiv === "C0" ? "C0" : requestBody)
    },
    contentType: "application/x-www-form-urlencoded; charset=euc-kr"
  });
}

export function approveApi(url, data) {
  return $.ajax(url, {
    type: "POST",
    dataType: "jsonp",
    data: {
      REQ: data
    },
    contentType: "application/x-www-form-urlencoded; charset=euc-kr"
  });
}
