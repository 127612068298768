import $ from "jquery";

export async function checkDaemonApi(url) {
  //let requestBody = "AP" + String.fromCharCode(2) + "0003S2R" + String.fromCharCode(3) + String.fromCharCode(13);
  return $.ajax(url, {
    type: "POST",
    contentType: "text/plain",
    dataType: "json",
    data: {},
  });
}

export function approveApi(url, data) {
  return $.ajax(url, {
    type: "POST",
    dataType: "json",
    contentType: "text/plain",
    data: JSON.stringify(data)
  });
}
