// TODO : 현재 개발이 완료된 VAN 모듈들 목록. VAN_KIND 공통 코드 값과 동일하게 설정. 개발이 모두 완료되면 VAN_KIND 공통 코드 값을 설정
import VanModuleCreateError from "@/error/van/VanModuleCreateError";

const VAN_TYPES = [
  "KSNET",
  "NHNKCP",
  "NICE",
  "BEST_PAY",
];

import KsnetVanModule from "@/van/ksnet/KsnetVanModule";
import NHNVanModule from "@/van/nhn/NHNVanModule";
import NiceVanModule from "@/van/nice/NiceVanModule";
import BestPayVanModule from "@/van/bestpay/BestPayVanModule";

/**
 *
 * @param {string} vanType - VAN 코드
 * @param {number} port - VAN 사 모듈 포트 번호
 * @returns {AbstractVanModule}
 */
export function getVanModule (vanType, port) {
  // 파라미터 체크
  if (!VAN_TYPES.includes(vanType)) {
    throw new VanModuleCreateError("등록되지 않은 VAN사입니다.");
  }
  // Van 모듈 return
  switch (vanType) {
    case "KSNET":
      return new KsnetVanModule(port);
    case "NHNKCP":
      return new NHNVanModule(port);
    case "NICE":
      return new NiceVanModule(port);
    case "BEST_PAY":
      return new BestPayVanModule(port);
    default:
      throw new VanModuleCreateError("등록되지 않은 VAN사입니다.");
  }
}
