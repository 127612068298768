import VanProcessError from "@/error/van/VanProcessError";
import NotImplementError from "@/error/NotImplementError";

const TYPE = 'VCAT';
let status = 5;
/** @type WebSocket */
let ws = null;

/**
 *
 * @param url
 * @param requestBody
 * @return {Promise<{data: Array, originResponse: String}>}
 */
export async function requestToWebSocket(url, requestBody) {
  return new Promise((resolve, reject) => {
    if (status !== 5) {
      reject(new VanProcessError("결제가 진행중입니다."));
    }

    let sendData = createSendData(requestBody);

    const webSocketUrl = `ws://${url}/${TYPE}`;

    if ("WebSocket" in window) {
      ws = new WebSocket(webSocketUrl);
      //연결성공
      ws.onopen = function () {
        status = 1;
        sendToWebSocket(ws, sendData);
      };

      //연결실패
      ws.onerror = function () {
        status = 6;
        reject(new VanProcessError('모듈 서버가 닫겨있습니다'));
      };

      //응답수신
      ws.onmessage = function (event) {
        status = 3;
        closeWebSocket();
        const result = getResponse(event.data);
        if (result.status) {
          resolve({data: result.response, originResponse: result.originResponse});
        } else {
          reject(result.error);
        }
      };

      //연결종료
      ws.onclose = function () {
        status = 5;
      };

    } else {
      //브라우저가 webSocket을 지원하지 않음.
      reject(new NotImplementError('해당 브라우저에서 결제 할 수 없습니다'));
    }
  });
}

/**
 *
 * @param {WebSocket} websocket
 * @param sendData
 */
function sendToWebSocket(websocket, sendData) {
  status = 2;
  websocket.send(sendData);
}

/**
 *
 * @param {WebSocket} websocket
 */
export function closeWebSocket() {
  status = 4;
  if (ws) {
    ws.close();
  }
}

function createSendData(requestString) {
  let bodyLength = requestString.NiceByteLength();
  let totalLength = bodyLength + 12;
  return nicePadding(totalLength, 4) + TYPE + "    " + nicePadding(bodyLength, 4) + requestString;
}

function nicePadding(length, width) {
  length = length + '';
  return length.length >= width ? length : new Array(width - length.length + 1).join('0') + length;
}

String.prototype.NiceByteLength = function () {
  let length = 0;

  for (let idx = 0; idx < this.length; idx++) {
    const c = escape(this.charAt(idx));

    if (c.length === 1) {
      length++;
    } else if (c.indexOf("%u") !== -1) {
      length += 2;
    } else if (c.indexOf("%") !== -1) {
      length += c.length / 3;
    }
  }

  return length;
};

function getResponse(response) {
  const bodyLength = response.substr(12,4);
  const resultCode = Number(response.substr(8,4));
  let errorMessage = '';
  let errorStatus = "FAIL";

  const result = {status: true, response: null, error: null, originResponse: response};
  // 이곳의 에러 코드는 기기에 대한 에러 코드
  switch (resultCode) {
    case 0:
      result.response = response.substr(16, bodyLength).split('\x1C');
      return result;
      // errorMessage = "정상";
      // errorStatus = "LATEST_CANCEL";
      // break;
    case 1:
      errorMessage = "NVCAT 실행 중이 아님";
      break;
    case 2:
      errorMessage = "거래금액이 존재하지 않음";
      break;
    case 3:
      errorMessage = "환경정보 읽기 실패";
      break;
    case 4:
      errorMessage = "NVCAT 연동오류 실패";
      errorStatus = "LATEST_CANCEL";
      break;
    case 5:
      errorMessage = "기타 응답데이터 오류";
      errorStatus = "LATEST_CANCEL";
      break;
    case 6:
      errorMessage = "카드리딩 타임아웃";
      break;
    case 7:
      errorMessage = "사용자 및 리더기 취소";
      break;
    case 8:
      errorMessage = "FALLBACK 거래요청";
      break;
    case 9:
      errorMessage = "기타오류";
      break;
    case 10:
      errorMessage = "IC우선거래 요청";
      break;
    case 11:
      errorMessage = "FALLBACK 거래 아님";
      break;
    case 12:
      errorMessage = "거래불가카드";
      break;
    case 13:
      errorMessage = "서명요청 오류";
      break;
    case 14:
      errorMessage = "요청 전문 데이터 오류";
      break;
    case 15:
      errorMessage = "카드리더 Port Open 오류";
      break;
    case 16:
      errorMessage = "직전거래 망상취소 불가(전문관리번호 없음)";
      break;
    case 17:
      errorMessage = "중복 요청 불가";
      break;
    case 18:
      errorMessage = "지원되지 않는 카드";
      break;
    case 19:
      errorMessage = "현금IC카드 복수계좌 미지원";
      break;
    case 20:
      errorMessage = "TIT 카드리더기 오류";
      break;
    case 21:
      errorMessage = "NVCAT 내부 망상취소 실패(해당 카드 카드사 확인 요망)";
      break;
    case 22:
      errorMessage = "현금IC 카드(다이소)";
      break;
    case 23:
      errorMessage = "현금IC MDB 존재하지 않음(다이소)";
      break;
    case 26:
      errorMessage = "리더기 응답데이터 수신 실패(결제 재요청 필요)";
      break;
    case 27:
      errorMessage = "리더기 요청 실패(결제 재요청 필요)";
      break;
    case 28:
      errorMessage = "서버 연결 실패(결제 재요청 필요)";
      break;
    case 29:
      errorMessage = "요청 전문 송신 실패(결제 재요청 필요)";
      break;
    default:
      errorMessage = "기타오류(".concat(resultCode.toString()).concat(")");
      break;
  }
  result.status = false;
  result.error = new VanProcessError(errorMessage, errorStatus, response, JSON.stringify(response.substr(16, bodyLength).split('\x1C'), null, 2));
  return result;
}
